import "./Dashboard.css";
import Text from '../../constants/Text';
import ENDPOINT from '../../constants/API';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Message, Form, Segment, ModalContent, ModalDescription, FormInput, FormTextArea, Input, FormDropdown, Grid, GridRow, GridColumn } from 'semantic-ui-react'
import VehicleDropdown from "../common/dropdown/VehicleDropdown";
import TagDropdown from "../common/dropdown/TagDropdown";
import EmployeeDropdown from '../common/dropdown/EmployeeDropdown';
import { DateInput } from "semantic-ui-calendar-react-17";
import moment from "moment";
import VisibleCondition from "../common/VisibleCondition";

export default function BookingToJobModal ({booking, refreshDashboardData}) {
    
    const authentication = useSelector(state => state.authentication);

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [timeOptionList, setTimeOptionList] = useState([]);
    
    const [selectedOldPartStatus, setSelectedOldPartStatus] = useState(null);
    const [selectedServiceTechnicians, setSelectedServiceTechnicians] = useState([]);
    const [selectedServiceLeadTechnician, setSelectedServiceLeadTechnician] = useState();
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedTagRepair, setSelectedTagRepair] = useState([]);
    const [selectedTagMaintenance, setSelectedTagMaintenance] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);

    const oldPartStatusOptions = [
        {
            key: 'KEEP_FOR_CUSTOMER',
            text: t(Text.UI_JOB_MODAL_KEEP),
            value: 'KEEP_FOR_CUSTOMER'
        },
        {
            key: 'DISCARD',
            text: t(Text.UI_JOB_MODAL_DISCARD),
            value: 'DISCARD'
        },
        

    ]
    
    const handleBookingToJobAction = async(event) => {
        setLoading(true);
        event.preventDefault();
        const convertJobFromBooking = Object.fromEntries(new FormData(event.target).entries());

        convertJobFromBooking.serviceTechnicians = selectedServiceTechnicians;
        convertJobFromBooking.serviceLeadTechnician = selectedServiceLeadTechnician;
        convertJobFromBooking.vehicle = selectedVehicle;
        convertJobFromBooking.repairTags = selectedTagRepair;
        convertJobFromBooking.maintenanceTags = selectedTagMaintenance;
        convertJobFromBooking.oldPartStatus = selectedOldPartStatus;
        convertJobFromBooking.expectedCollectionTime = moment(selectedDate + " " + selectedTime, "DD-M-yyyy hh:mm a").toISOString(true);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(convertJobFromBooking)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT+"/convertFromBooking",options)
        const body = await response.json();

        if(response.ok)
        {
            refreshDashboardData();
            setLoading(false)
            closeBookingToJobModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const handleSelectVehicle = (event,data) => {
        event.preventDefault();
        setSelectedVehicle(data.value);
    }

    const handleSelectTagRepair = (event,data) => {
        event.preventDefault();
        setSelectedTagRepair(data.value);
    }

    const handleSelectTagMaintenance = (event,data) => {
        event.preventDefault();
        setSelectedTagMaintenance(data.value);
    }

    const handleSelectServiceLeadTechnician = (event,data) => {
        event.preventDefault();
        setSelectedServiceLeadTechnician(data.value);
    }

    const handleSelectServiceTechnicians = (event,data) => {
        event.preventDefault();
        setSelectedServiceTechnicians(data.value);
    }

    const handleSelectOldPartStatus = (event,data) => {
        event.preventDefault();
        setSelectedOldPartStatus({key:data.value});
    }

    const convertToBookingButton = () => {
        return(
            <Button fluid={false} disabled={booking.jobCreated} icon='square share'/>
        )
    }

    const closeBookingToJobModal = () => {
        setSelectedServiceTechnicians(null);
        setSelectedVehicle(null);
        
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openBookingToJobModal = () => {
        setOpen(true);
    }

    const customerVehicleFields = () => {
        if(!booking.customerVehicleData) {
            return(
                <>
                    <VehicleDropdown handleSelectVehicle={handleSelectVehicle}/>
                    <FormInput label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} type="text" name="vinNumber" placeholder="Vin Number"/>  
                </>
            )
        }
        else if (!booking.customerVehicleData.vinNumber) {
            return(        
                <FormInput label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} type="text" name="vinNumber" placeholder="Vin Number"/>         
            )
        }
    }

    
    useEffect(() => {
        const fetchAvailableBookingTimeList = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authentication.authorization
                },
            }

            const response = await fetch(ENDPOINT.BOOKING_ENDPOINT + "/availableBookingTime?date=" + moment().format("DD-M-yyyy"), options);
            const body = await response.json();


            var result = body.map(val => {
                var obj = {};
                obj['key'] = val;
                obj['text'] = val;
                obj['value'] = val;
                return obj;
            });

            setTimeOptionList(result);
        }

        fetchAvailableBookingTimeList();
    }, [authentication]);

    return (
        <Modal closeIcon open={open} onClose={closeBookingToJobModal} onOpen={openBookingToJobModal} trigger={convertToBookingButton()} closeOnDimmerClick={false}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleBookingToJobAction} error={error}>
                            <Input type="hidden" name="booking" value={booking.id} />
                            <EmployeeDropdown label={t(Text.GENERIC_DATA_JOB_SERVICELEADTECHNICIAN)} placeholder={t(Text.GENERIC_DATA_JOB_SERVICELEADTECHNICIAN)} required={true} multiple={false} handleSelectEmployee={handleSelectServiceLeadTechnician}/>
                            <EmployeeDropdown label={t(Text.GENERIC_DATA_JOB_SERVICETECHNICIANS)} placeholder={t(Text.GENERIC_DATA_JOB_SERVICETECHNICIANS)} required={false} multiple={true} handleSelectEmployee={handleSelectServiceTechnicians}/>
                            <FormTextArea label={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} type="text" name="customerRequest" placeholder={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} defaultValue={booking.comments}/>
                            <FormInput required label={t(Text.GENERIC_DATA_SERVICE_MILEAGE)} type="text" name="mileage" placeholder={t(Text.GENERIC_DATA_SERVICE_MILEAGE)}/>
                            <FormTextArea label={t(Text.UI_JOB_DETAILS_ABNORMALITIES)} type="text" name="abnormalities" placeholder={t(Text.UI_JOB_DETAILS_ABNORMALITIES)}/>
                            <TagDropdown name={'Repair'} value={selectedTagRepair} label={t(Text.GENERIC_DATA_TAG_REPAIR)} category={'REPAIR'} placeholder={t(Text.GENERIC_DATA_TAG_REPAIR_PLACEHOLDER)} handleSelectTag={handleSelectTagRepair}/>
                            <TagDropdown name={'Maintenance'} value={selectedTagMaintenance} label={t(Text.GENERIC_DATA_TAG_MAINTENANCE)} category={'MAINTENANCE'} placeholder={t(Text.GENERIC_DATA_TAG_MAINTENANCE_PLACEHOLDER)} handleSelectTag={handleSelectTagMaintenance}/>
                            {customerVehicleFields()}
                            <FormDropdown required label={t(Text.UI_JOB_DETAILS_OLDPARTSTATUS)} name="oldPartStatus" placeholder={t(Text.UI_JOB_MODAL_OLDPARTS)} fluid selection options={oldPartStatusOptions} onChange={handleSelectOldPartStatus}/>
                            <Grid>
                                <GridRow columns={2}>
                                    <GridColumn>
                                        <DateInput
                                            required
                                            name="expectedCollectionDate"
                                            label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_DATE)}
                                            placeholder={t(Text.GENERIC_SELECT_DATE)}
                                            iconPosition="left"
                                            dateFormat='DD-M-YYYY'
                                            minDate={moment()}
                                            value={selectedDate}
                                            onChange={(event, data) => setSelectedDate(data.value)}
                                        />
                                    </GridColumn>
                                    <GridColumn>
                                        <VisibleCondition condition={selectedDate !== ""}>
                                            <FormDropdown
                                                label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_TIME)}
                                                placeholder={t(Text.GENERIC_SELECT_TIME)}
                                                name="expectedCollectionTime"
                                                fluid
                                                search
                                                selection
                                                required
                                                options={timeOptionList}
                                                value={selectedTime}
                                                onChange={(event, data) => setSelectedTime(data.value)}
                                            />
                                        </VisibleCondition>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            <br/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}
