import "./Dashboard.css";
import Roles from "../../constants/Roles";
import Text from '../../constants/Text';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, AccordionContent, AccordionTitle, Card, CardContent, Container, Grid, GridColumn, Header, Icon, Label, Menu, MenuItem, Segment, Transition } from 'semantic-ui-react'
import { RoleAllowed, VisibleCondition } from '..';
import { convertDateTimeToDefaultTime } from '../utils/DateTimeFormatUtil';
import ClampLines from 'react-clamp-lines';
import BookingToJobModal from './BookingToJobModal';
import NewJobModal from "../job/new/NewJobModal";
import JobCard from "./JobCard";
import NewBookingModal from "../booking/modals/NewBookingModal";

export default function AllJobsDashboard({refreshDashboardData}) {

    const confirmedTodayBookings = useSelector(state => state.confirmedTodayBookings);
    const startedJobs = useSelector(state => state.startedJobs);
    const checkedInJobs = useSelector(state => state.checkedInJobs);
    const completedJobs = useSelector(state => state.completedJobs);
    const { t } = useTranslation();
    
    const gridColumns = 4;

    const confirmedTodayBookingsList = confirmedTodayBookings.data;
    const startedJobsList = startedJobs.data;
    const checkedInJobsList = checkedInJobs.data;
    const completedJobsList = completedJobs.data;

    const [activeSegments, setActiveSegments] = useState([0]);
    const [onGoingJobsLoading, setOnGoingJobLoading] = useState(false);

    useEffect(() => {
        !checkedInJobs.loading && !startedJobs.loading && !completedJobs.loading ? setOnGoingJobLoading(false) : setOnGoingJobLoading(true);
    },[checkedInJobs.loading, startedJobs.loading, completedJobs.loading])
    
    const toggleSegment = (index) => {
        const newActiveSegments = [...activeSegments];
        const currentIndexPosition = activeSegments.indexOf(index);

        if (newActiveSegments.includes(index)) {
            newActiveSegments.splice(currentIndexPosition, 1)
            setActiveSegments(newActiveSegments);
        }
        else {
            newActiveSegments.push(index)
            setActiveSegments(newActiveSegments);
        }
    }

    const activeBookingsTitle = () => {
        return (
            <Menu secondary>
                <MenuItem onClick={() => toggleSegment(0)} as={"div"}>
                    <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                        <Icon name='dropdown' />{t(Text.UI_DASHBOARD_BOOKING_HEADER)} - {confirmedTodayBookingsList ? confirmedTodayBookingsList.length : 0}
                    </Header>
                </MenuItem>
                <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                    <MenuItem position="right">
                        <NewBookingModal refreshDashboardData={refreshDashboardData} />
                    </MenuItem>
                </RoleAllowed>
            </Menu>
        )
    }

    const checkedInJobsTitle = () => {

        const cil = checkedInJobsList ? checkedInJobsList.length : 0;
        const sl = startedJobsList ? startedJobsList.length : 0;
        const cl = completedJobsList ? completedJobsList.length : 0;
        const tl = cil + sl + cl;

        return (
            <>
                <Segment raised>
                    <Label size="large" as='a' color="red" ribbon attached="top">{t(Text.UI_DASHBOARD_ONGOINGJOB_HEADER)} - {tl} <VisibleCondition condition={onGoingJobsLoading}>&nbsp;&nbsp;<Icon name="notched circle" loading/></VisibleCondition></Label>
                </Segment>
                <Menu secondary>
                    <MenuItem disabled={onGoingJobsLoading} onClick={() => toggleSegment(1)} as={"div"}>
                        <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                            <Icon name='dropdown' />{t(Text.UI_DASHBOARD_CHECKEDINJOB_HEADER)} - {checkedInJobsList ? checkedInJobsList.length : 0}
                        </Header>
                    </MenuItem>
                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                        <MenuItem position="right">
                            <NewJobModal refreshDashboardData={refreshDashboardData} />
                        </MenuItem>
                    </RoleAllowed>
                </Menu>
            </>
        )
    }

    const startedJobsTitle = () => {
        return (
            <Menu secondary>
                <MenuItem disabled={onGoingJobsLoading} onClick={() => toggleSegment(2)} as={"div"}>
                    <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                        <Icon name='dropdown' />{t(Text.UI_DASHBOARD_STARTED_HEADER)} - {startedJobsList ? startedJobsList.length : 0}
                    </Header>
                </MenuItem>
            </Menu>
        )
    }

    const completedJobsTitle = () => {
        return (
            <Menu secondary>
                <MenuItem disabled={onGoingJobsLoading} onClick={() => toggleSegment(3)} as={"div"}>
                    <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                        <Icon name='dropdown' />{t(Text.UI_DASHBOARD_COMPLETEDJOB_HEADER)} - {completedJobsList ? completedJobsList.length : 0}
                    </Header>
                </MenuItem>
            </Menu>
        )
    }

    const renderConfirmedTodayBookings = (item) => {
        const bookings = item ? item : [];
        return bookings.map((booking, idx) => {
            const firstName = booking?.customerData?.firstName ? booking.customerData.firstName : '';
            const lastName = booking?.customerData?.lastName ? booking.customerData.lastName : '';
            return (
                <GridColumn key={idx} width={gridColumns}>
                    <Card color='red' fluid>
                        <Menu className="menu-header" secondary attached>
                            <MenuItem header className="menu-item-header" position="left" content={booking.registrationNumber} />
                            <MenuItem header className="menu-item-header" position="right" content={booking.status.value.toUpperCase()} />
                        </Menu>
                        <CardContent textAlign="left">
                            <Container>
                                <div>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_BOOKING_BOOKINGTIME)}:</h4>
                                    <p className='no-margin'>{convertDateTimeToDefaultTime(booking.bookingDateTime)}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMERNAME)}:</h4>
                                    <p className='no-margin'>{firstName + " " + lastName}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_MOBILENUMBER)}:</h4>
                                    <p className='no-margin'>{booking?.customerData?.mobileNumber}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_VEHICLE_TYPE)}:</h4>
                                    <p className='no-margin'>{(booking.carBrand + booking.carModel + booking.carSeries).toUpperCase()}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}:</h4>
                                    <ClampLines text={booking.comments} id="default" lines={4} ellipsis="..." moreText="More" lessText="Less" />
                                </div>
                            </Container>
                        </CardContent>
                        <CardContent textAlign="right">
                            <BookingToJobModal booking={booking} refreshDashboardData={refreshDashboardData} />
                        </CardContent>
                    </Card>
                </GridColumn>
            )
        }
        )
    }

    const renderJobCards = (item) => {
        const jobs = item ? item : [];
        return jobs.map((job, idx) => {
            return (
                <GridColumn key={idx} width={gridColumns}>
                    <JobCard jobHeader={job} />
                </GridColumn>
            )
        }
        )
    }

    return (
        <>
            <Accordion styled fluid>
                <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                    <AccordionTitle active={activeSegments.includes(0)} icon={false} content={activeBookingsTitle()} className='align-left no-pointer' />
                    <Transition visible={activeSegments.includes(0)} animation='fade down' duration={100}>
                        <AccordionContent>
                            <Segment basic loading={confirmedTodayBookings.loading}>
                                <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderConfirmedTodayBookings(confirmedTodayBookingsList)} />
                            </Segment>
                        </AccordionContent>
                    </Transition>
                </RoleAllowed>
                <AccordionTitle active={activeSegments.includes(1)} icon={false} content={checkedInJobsTitle()} className='align-left no-pointer' />
                <Transition visible={activeSegments.includes(1)} animation='fade down' duration={100}>
                    <AccordionContent >
                        <Segment basic loading={checkedInJobs.loading}>
                            <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(checkedInJobsList)} />
                        </Segment>
                    </AccordionContent>
                </Transition>
                <AccordionTitle active={activeSegments.includes(2)} icon={false} content={startedJobsTitle()} className='align-left no-pointer' />
                <Transition visible={activeSegments.includes(2)} animation='fade down' duration={100}>
                    <AccordionContent >
                        <Segment basic loading={startedJobs.loading}>
                            <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(startedJobsList)} />
                        </Segment>
                    </AccordionContent>
                </Transition>
                <AccordionTitle active={activeSegments.includes(3)} icon={false} content={completedJobsTitle()} className='align-left no-pointer' />
                <Transition visible={activeSegments.includes(3)} animation='fade down' duration={100}>
                    <AccordionContent >
                        <Segment basic loading={startedJobs.loading}>
                            <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(completedJobsList)} />
                        </Segment>
                    </AccordionContent>
                </Transition>
            </Accordion>
        </>
    );
}
