import "./JobDetails.css"
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Confirm, Grid, GridColumn, GridRow, Header, Image, Label, List, ListContent, ListItem, Menu, MenuItem, Message, Progress, Segment } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { isDesktop, isMobile } from "react-device-detect";
import { Navigation, VisibleCondition, RoleAllowed } from '../..';
import { convertDateTimeToTime } from "../../utils/DateTimeFormatUtil";
import { getJob } from "../../../redux-slice/JobSlice";
import { getProfile } from "../../../redux-slice/ProfileSlice";
import { JOB_ENDPOINT, QUOTE_ENDPOINT } from "../../../constants/API";
import { TimerFlip } from "../../common/TimerFlip";
import Text from '../../../constants/Text';
import PATH from '../../../constants/Path';
import DATEFORMAT from '../../../constants/Global';
import ROLE from "../../../constants/Roles";
import UpdateJobCustomerVehicleModal from "./UpdateJobCustomerVehicleModal";
import UpdateJobMileageModal from "./UpdateJobMileageModal";
import UpdateJobServiceAdvisorModal from "./UpdateJobServiceAdvisorModal";
import UpdateJobServiceTechniciansModal from "./UpdateJobServiceTechniciansModal";
import UpdateJobDescriptionModal from "./UpdateJobDescriptionModal";
import UpdateJobAbnormalitiesModal from "./UpdateJobAbnormalitiesModal";
import UpdateJobTagsModal from "./UpdateJobTagsModal";
import UpdateJobCustomerRequestModal from "./UpdateJobCustomerRequestModal";
import UpdateJobOldPartsModal from "./UpdateJobOldPartsModal";
import UpdateJobProgressToCheckOutModal from "./UpdateJobProgressToCheckOutModal";
import UpdateJobProgressToCompletedModal from "./UpdateJobProgressToCompletedModal";
import UpdateJobTransactionNumberModal from "./UpdateJobTransactionNumber";
import UpdateJobServiceLeadTechnicianModal from "./UpdateJobServiceLeadTechnicianModal";
import JobAttachments from "./JobAttachments";
import CreateNewTagModal from "../../tag/new/CreateNewTagModal";
import Roles from "../../../constants/Roles";
import JobServiceHistory from "./JobServiceHistory";
import UpdateCustomerFirstNameModal from "../../customer/details/UpdateCustomerFirstName";
import UpdateCustomerLastNameModal from "../../customer/details/UpdateCustomerLastName";
import UpdateCustomerMobileNumberModal from "../../customer/details/UpdateCustomerMobileNumber";
import UpdateCustomerAddressModal from "../../customer/details/UpdateCustomerAddress";
import UpdateCustomerVehicleRegistrationNumber from "../../customerVehicle/details/UpdateCustomerVehicleRegistrationNumber";
import UpdateCustomerVehicleVinNumberModal from "../../customerVehicle/details/UpdateCustomerVehicleVinNumber";
import UpdateCustomerVehicleVehicleModal from "../../customerVehicle/details/UpdateCustomerVehicleVehicle";
import NewIncidentModal from "../../incident/new/NewIncidentModal";
import IncidentDetails from "../../incident/details/IncidentDetails";
import moment from "moment";
import Quotation from "../../quotation/Quotation";

let startedObject ={
    "key":"STARTED",
    "value":"Started",
}
let checkedOutObject ={
    "key":"CHECKED_OUT",
    "value":"Checked Out",
}
let onHoldObject ={
    "key":"ON_HOLD",
    "value":"On Hold",
}
let cancelledObject ={
    "key":"CANCELLED",
    "value":"Cancelled",
}
let completedObject ={
    "key":"COMPLETED",
    "value":"Completed",
}

export default function JobDetails() {
    const authentication = useSelector(state => state.authentication);
    const job = useSelector(state => state.job);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const {id} = useParams();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const [activeTab, setActiveTab] = useState("details");
    const [confirmStart, setConfirmStart] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [confirmRestart, setConfirmRestart] = useState(false);

    const [jobStatusChangeHistory, setJobStatusChangeHistory] = useState([]);
    const [loadingJobStatusChangeHistory, setLoadingJobStatusChangeHistory] = useState(false);
    const [checkIn, setCheckIn] = useState(null);
    const [startJob, setStartJob] = useState(null);
    const [quoteNotValid, setQuoteValid] = useState(false);
    const [quoteNotValidMessage, setQuoteNotValidMessage] = useState(null);

    let jobStatusPercentage = 0;
    let statusTextColor = 'green';
    let statusIsActive = true;

    const getJobStatusChangeHistory = useCallback(async () =>{
        setLoadingJobStatusChangeHistory(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(JOB_ENDPOINT+"/"+id+"/statusChangeHistory",options)
        const body = await response.json();
        if(response.ok)
        {
            setJobStatusChangeHistory(body)
        }
        setLoadingJobStatusChangeHistory(false);
    },[authentication,id]);

    const getQuote = useCallback(async () => {
        try{
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization': authentication.authorization
                }
            }
            const response = await fetch(QUOTE_ENDPOINT+"/job/"+id,options)
            const body = await response.json();
            if(response.ok)
            {
                let quoteNotValid = false;
                let text = "The following parts are still not available, are you sure you want to start the job?\n\n"
                body?.lineItem?.filter(n => n.stock === "NO").forEach((n,index) => {
                    text = text.concat((index + 1) + ") ").concat(n.sku + " ").concat(n.name + " (").concat(n.type + ").\n");
                    quoteNotValid = true;
                })

                setQuoteValid(quoteNotValid);
                setQuoteNotValidMessage(text);
            }
        }
        catch(error){
            console.log("Warning Error " + error.name)
        }
    },[authentication,id])

    const onMount = useCallback(() => {
        if(!authentication.authenticated)
        {
            navigate(PATH.LOGIN_PAGE);
        }
        else
        {
            dispatch(getProfile());
            dispatch(getJob({jobId:id}));
            getJobStatusChangeHistory();
            getQuote();
        }
    },[authentication,id,dispatch,navigate,getJobStatusChangeHistory,getQuote]);

    useEffect(() => {
        onMount();
    },[onMount]);

    useEffect(() => {
        const data = job.data;
        document.title = "Job Details";
        if(data && !job.loading)
        {
            const firstName = data?.serviceData?.customerData?.firstName;
            const model = data?.serviceData?.customerVehicleData?.vehicleData?.model;
            const brand = data?.serviceData?.customerVehicleData?.vehicleData?.brand;
            const registrationNumber = data?.serviceData?.customerVehicleData?.registrationNumber;
            const title = firstName?.concat(" ",model?model:brand," ","(",registrationNumber,")");
            document.title = title;
        }
    },[job.data,job.loading])

    useEffect(() => {
        if(jobStatusChangeHistory?.length > 0 && !loadingJobStatusChangeHistory){
            let checkedInStatuses = jobStatusChangeHistory.filter(n => n.toStatus?.key === "CHECKED_IN");
            let startedStatuses = jobStatusChangeHistory.filter(n => n.toStatus?.key === "STARTED");

            if(checkedInStatuses.length > 0){
                setCheckIn(checkedInStatuses[checkedInStatuses.length - 1])
            }

            if(startedStatuses.length > 0){
                setStartJob(startedStatuses[startedStatuses.length - 1])
            }
        }
    },[setCheckIn,setStartJob,jobStatusChangeHistory,loadingJobStatusChangeHistory])

    const handleMenuClick = (event,data) => {
        event.preventDefault();

        if(data.name === "details"){
            getQuote();
        }

        setActiveTab(data.name);
    }

    const updateJobStatus = async (statusValue,transactionNumber) =>{
        setLoading(true)
        const updateJobStatusValue = {
            "jobStatus": statusValue,
            "transactionNumber":transactionNumber
        };
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateJobStatusValue)
        }
        const response = await fetch(JOB_ENDPOINT+"/"+id+"/updateProgress",options)
        const body = await response.json();
        if(response.ok)
        {
            dispatch(getJob({jobId:id}))
            setJobStatusValues(job?.data?.jobStatus?.key)
        }
        else
        {
            setError(true);
            setErrorMessage(body.message);
        }
        setConfirmCancel(false);
        setConfirmRestart(false);
        setLoading(false);
        getJobStatusChangeHistory();
    }

    const setJobStatusValues = () =>{
        switch (job?.data?.jobStatus?.key)
        {
            case "CHECKED_IN":
                jobStatusPercentage = 25;
                statusTextColor = 'green';
                statusIsActive = true;
                break;
            case "STARTED":
                jobStatusPercentage = 50;
                statusTextColor = 'green';
                statusIsActive = true;
                break;
            case "COMPLETED":
                jobStatusPercentage = 75;
                statusTextColor = 'green';
                statusIsActive = true;
                break;
            case "CHECKED_OUT":
                jobStatusPercentage = 100;
                statusTextColor = 'green';
                statusIsActive = true;
                break;
            case "CANCELLED":
                jobStatusPercentage = 100;
                statusTextColor = 'red';
                statusIsActive = false;
                break;
            case "ON_HOLD":
                jobStatusPercentage = 100;
                statusTextColor = 'yellow';
                statusIsActive = true;
                break;
            default:
                jobStatusPercentage = 0;
                statusTextColor = 'green';
                statusIsActive = true;
            return
        }
    }

    const holdButton = () => {
        var status = job?.data?.jobStatus?.key;
        return(
            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                <VisibleCondition condition={status !== onHoldObject.key && status !== cancelledObject.key && status !== completedObject.key && status !== checkedOutObject.key}>
                    <Button icon="pause" floated="left" color="yellow" onClick={()=> updateJobStatus(onHoldObject,null)} content="Hold"/>
                </VisibleCondition>
            </RoleAllowed>
        )
    }

    const cancelButton = () => {
        var status = job?.data?.jobStatus?.key;
        return(
            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                <VisibleCondition condition={status !== cancelledObject.key && status !== completedObject.key && status !== checkedOutObject.key}>
                    <Button icon="cancel" floated="left" color="red" onClick={() => {setConfirmCancel(true)}} content="Cancel"/>
                    <Confirm header={t(Text.WARNING_HEADER)} content={t(Text.WARNING_JOB_CANCEL)} open={confirmCancel} onCancel={() => {setConfirmCancel(false)}} onConfirm={()=> updateJobStatus(cancelledObject,null)} />
                </VisibleCondition>
            </RoleAllowed>
        )
    }

    const restartButton = () => {
        var status = job?.data?.jobStatus?.key;
        return(
            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                <VisibleCondition condition={status !== cancelledObject.key && status === completedObject.key && status !== checkedOutObject.key}>
                    <Button icon="refresh" floated="left" color="black" onClick={() => {setConfirmRestart(true)}} content="Restart"/>
                    <Confirm header={t(Text.WARNING_HEADER)} content={t(Text.WARNING_JOB_RESTART)} open={confirmRestart} onCancel={() => {setConfirmRestart(false)}} onConfirm={()=> updateJobStatus(startedObject,null)} />
                </VisibleCondition>
            </RoleAllowed>
        )
    }

    const startButton = () => {
        return(
            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER, ROLE.ROLE_ADVISOR, ROLE.ROLE_TECHNICIAN]}>
                <VisibleCondition condition={quoteNotValid}>
                    <Button floated="right" color="blue" onClick={()=> setConfirmStart(true)}>Start Job</Button>;
                    <Confirm className="support-line-break" header={t(Text.WARNING_HEADER)} content={quoteNotValidMessage} open={confirmStart} onCancel={() => {setConfirmStart(false)}} onConfirm={()=> updateJobStatus(startedObject,null)} />
                </VisibleCondition>
                <VisibleCondition condition={!quoteNotValid}>
                    <Button floated="right" color="blue" onClick={()=> updateJobStatus(startedObject,null)}>Start Job</Button>;
                </VisibleCondition>
            </RoleAllowed>
        )
    }

    const nextProgressButton = () => {
        switch (job?.data?.jobStatus?.key)
        {
            case "CHECKED_IN":
                return(startButton());
            case "STARTED":
                return(
                    <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                        <UpdateJobProgressToCompletedModal completedObject={completedObject} onClick={updateJobStatus} error={error} setError={setError} errorMessage={errorMessage} setErrorMessage={setErrorMessage} floated="right"/>
                    </RoleAllowed>
                );
            case "COMPLETED":
                return(
                    <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                        <UpdateJobProgressToCheckOutModal checkedOutObject={checkedOutObject} onClick={updateJobStatus} error={error} setError={setError} errorMessage={errorMessage} setErrorMessage={setErrorMessage} floated="right"/>
                    </RoleAllowed> 
                );
            case "ON_HOLD":
                return(
                    <Button floated="right" color="blue" onClick={()=> updateJobStatus(onHoldObject,null)}>Resume Job</Button>
                );
            default:
                return(<></>)
        }
    }

    const menu = () => {
        return(
            <Menu className='no-margin' style={{overflowX:'scroll'}} pointing secondary color="red">
                <MenuItem icon="list alternate outline" name="details" onClick={handleMenuClick} active={activeTab === "details"}/>
                <MenuItem icon="file" content={isDesktop?"Quotation":"Parts List"} name="quotation" onClick={handleMenuClick} active={activeTab === "quotation"}/>
                <MenuItem icon="history" name="serviceHistory" onClick={handleMenuClick} active={activeTab === "serviceHistory"}/>
                <MenuItem icon="paperclip" name="attachments" onClick={handleMenuClick} active={activeTab === "attachments"}/>
                <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                    <VisibleCondition condition={job?.data?.incidentPresent}>
                        <MenuItem icon="exclamation triangle" name="incident" onClick={handleMenuClick} active={activeTab === "incident"}/>
                    </VisibleCondition>
                </RoleAllowed>
            </Menu>
        )
    }

    const serviceTechnicianList = (job) => {
        return(
            <List horizontal={isDesktop} className='no-margin' verticalAlign='middle'>
                {job.serviceTechnicians.map((value)=>(
                    <ListItem key={value.id}>
                        <Image avatar src={value.picture} />
                        <ListContent>{value.firstName+ " " + value.lastName}</ListContent>
                    </ListItem>
                    )
                )}
            </List>
        )
    }
    
    const serviceTagRepairList = (job) => {
        return(
            <List horizontal={true} className='no-margin' verticalAlign='middle'>
                {job.serviceData?.repairTags?.map((value)=>(
                    <ListItem key={value.id}>
                        <ListContent><Label color="grey">{value.name}</Label></ListContent>
                    </ListItem>
                    )
                )}
            </List>
        )
    }

    const serviceTagMaintenanceList = (job) => {
        return(
            <List horizontal={true} className='no-margin' verticalAlign='middle'>
                {job.serviceData?.maintenanceTags?.map((value)=>(
                    <ListItem key={value.id}>
                        <ListContent><Label color="grey">{value.name}</Label></ListContent>
                    </ListItem>
                    )
                )}
            </List>
        )
    }

    const renderJobInformation = () => {
        const firstName = job.data?.serviceData?.customerData?.firstName!==null?job.data?.serviceData?.customerData?.firstName:''
        const lastName = job.data?.serviceData?.customerData?.lastName!==null?job.data?.serviceData?.customerData?.lastName:'';
        return(
            <GridRow>
                <GridColumn width={16}>
                    <GridRow columns={2}>
                        <GridColumn style={{paddingBottom:10}} width={8}>
                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                <VisibleCondition condition={!job.data?.incidentPresent}>
                                    <NewIncidentModal job={job?.data}/>
                                </VisibleCondition>
                            </RoleAllowed>
                        </GridColumn>
                        <GridColumn width={8}>
                            <Header textAlign="left">{t(Text.UI_JOB_DETAILS_JOBINFORMATION)}</Header>
                        </GridColumn>
                    </GridRow>
                    <Segment textAlign="left">
                        <Grid stackable>
                            <GridRow>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 attached="left" className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMER)}</h4>
                                        <Image src={job.data?.serviceData?.customerData?.picture} avatar />
                                        <span className="no-margin">{ firstName + " " + lastName }</span>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_CARPLATENUMBER)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                <UpdateJobCustomerVehicleModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <span className="no-margin">{job.data?.serviceData?.customerVehicleData?.registrationNumber}</span>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_SERVICE_MILEAGE)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobMileageModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <span className="no-margin">{job.data?.serviceData?.mileage}</span>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_OLDPARTSTATUS)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                <UpdateJobOldPartsModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <p className="no-margin">{job.data?.oldPartStatus?job.data.oldPartStatus.value:"-"}</p>
                                    </div>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_SERVICEADVISOR)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                <UpdateJobServiceAdvisorModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <Image src={job.data?.serviceAdvisor?.picture} avatar />
                                        <span className="no-margin">{job.data?.serviceAdvisor?.firstName + " " + job.data?.serviceAdvisor?.lastName}</span>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_SERVICELEADTECHNICIAN)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobServiceLeadTechnicianModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <Image src={job.data?.serviceLeadTechnician?.picture} avatar />
                                        <span className="no-margin">{job.data?.serviceLeadTechnician?.firstName + " " + job.data?.serviceLeadTechnician?.lastName}</span>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_SERVICETECHNICIANS)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobServiceTechniciansModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        {serviceTechnicianList(job.data)}
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_TAG_REPAIR)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                <UpdateJobTagsModal job={job.data} label={t(Text.GENERIC_DATA_TAG_REPAIR)} placeholder={t(Text.GENERIC_DATA_TAG_REPAIR_PLACEHOLDER)} category="REPAIR" defaultValue={job?.data?.serviceData?.repairTags?.map(n => n.id)}/>
                                            </RoleAllowed>
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                <CreateNewTagModal category={"REPAIR"} trigger={<Button className='button-margin' fluid={false} compact size='mini' icon='plus'/>}/>
                                            </RoleAllowed>
                                        </h4>
                                        {serviceTagRepairList(job.data)}
                                    </div>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_TAG_MAINTENANCE)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                <UpdateJobTagsModal job={job.data} label={t(Text.GENERIC_DATA_TAG_MAINTENANCE)} placeholder={t(Text.GENERIC_DATA_TAG_MAINTENANCE_PLACEHOLDER)} category="MAINTENANCE" defaultValue={job?.data?.serviceData?.maintenanceTags?.map(n => n.id)}/>
                                            </RoleAllowed>
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER]}>
                                                <CreateNewTagModal category={"MAINTENANCE"} trigger={<Button className='button-margin' fluid={false} compact size='mini' icon='plus'/>}/>
                                            </RoleAllowed>
                                        </h4>
                                        {serviceTagMaintenanceList(job.data)}
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_STARTDATETIME)}</h4>
                                        <p className="no-margin">{job.data?.jobStartDateTime?convertDateTimeToTime(job.data.jobStartDateTime, DATEFORMAT.DATE_TIME_FORMAT):"-"}</p>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_ENDDATETIME)}</h4>
                                        <p className="no-margin">{job.data?.jobEndDateTime?convertDateTimeToTime(job.data.jobEndDateTime, DATEFORMAT.DATE_TIME_FORMAT):"-"}</p>
                                    </div>
                                </GridColumn>
                                <VisibleCondition condition={job.data?.jobStatus?.key === "CHECKED_OUT"}>
                                    <GridColumn width={4}>
                                        <div>
                                            <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_TRANSACTIONINVOICENUMBER)}
                                                <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                    <UpdateJobTransactionNumberModal job={job.data}/>
                                                </RoleAllowed>
                                            </h4>
                                            <p className="no-margin">{job.data?.serviceData?.transactionNumber?job.data?.serviceData?.transactionNumber:"-"}</p>
                                        </div>
                                    </GridColumn>
                                </VisibleCondition>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            </GridRow>
        );
    }

    const renderJobDetails = () => {
        return(
            <GridRow>
                <GridColumn width={16}>
                    <Header textAlign="left">{t(Text.UI_JOB_DETAILS_JOBDETAILS)}</Header>
                    <Segment textAlign="left">
                        <Grid stackable>
                            <GridRow>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.GENERIC_DATA_JOB_JOBID)}</h4>
                                        <VisibleCondition condition={job.data?.jobId}>
                                            <p className="no-margin">{job.data?.jobId}</p>
                                        </VisibleCondition>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 attached="left" className="no-margin">{t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobCustomerRequestModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <VisibleCondition condition={job.data?.customerRequest}>
                                            <p className="support-line-break">{job.data?.customerRequest}</p>
                                        </VisibleCondition>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 attached="left" className="no-margin">{t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobDescriptionModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <VisibleCondition condition={job.data?.description}>
                                            <p className="support-line-break">{job.data?.description}</p>
                                        </VisibleCondition>
                                    </div>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div>
                                        <h4 className="no-margin">{t(Text.UI_JOB_DETAILS_ABNORMALITIES)}
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR,ROLE.ROLE_TECHNICIAN]}>
                                                <UpdateJobAbnormalitiesModal job={job.data}/>
                                            </RoleAllowed>
                                        </h4>
                                        <VisibleCondition condition={job.data?.abnormalities}>
                                            <p className="support-line-break">{job.data?.abnormalities}</p>
                                        </VisibleCondition>
                                    </div>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            </GridRow>
        );
    }

    const renderJobProgress = () => {
        return(
            <GridRow>
                <GridColumn width={16}>
                    <Header textAlign="left">{t(Text.UI_JOB_DETAILS_JOBPROGRESS)}</Header>
                    <Segment loading={loading}>
                        <Grid>
                            <GridRow>
                                <GridColumn width={8}>
                                    <ButtonGroup floated="left" vertical={isMobile}>
                                        {restartButton()}
                                        {holdButton()}
                                        {cancelButton()}
                                    </ButtonGroup>
                                </GridColumn>
                                <GridColumn textAlign="right" width={8}>
                                    {nextProgressButton()}
                                </GridColumn>
                            </GridRow>
                            <GridRow className="progress-bar-row">
                                <GridColumn width={16}>
                                    {setJobStatusValues()}
                                    <Progress color={statusTextColor} active={statusIsActive} percent={jobStatusPercentage} />
                                </GridColumn>
                            </GridRow>
                            <GridRow divided className="progress-bar-label-row">
                                <GridColumn textAlign="right" width={4}>
                                    <h5>Checked-In</h5>
                                </GridColumn>
                                <GridColumn textAlign="right" width={4}>
                                    <h5>Started</h5>
                                </GridColumn>
                                <GridColumn textAlign="right" width={4}>
                                    <h5>Completed </h5>
                                </GridColumn>
                                <GridColumn textAlign="right" width={4}>
                                    <h5>Checked-Out</h5>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            </GridRow>
        );
    }

    const renderVehicleInformation = () => {
        return(
            <GridRow>
                <GridColumn>
                    <Header textAlign="left">{t(Text.UI_JOB_DETAILS_CUSTOMERVEHICLEINFORMATION)+" "}</Header>
                    <Segment textAlign="left">
                        <Grid columns={2} stackable>
                            <GridRow>
                                <GridColumn verticalAlign="middle" width={6}>
                                    <Image src={job.data?.serviceData?.customerVehicleData?.vehicleData.picture} size="small"/>
                                </GridColumn>
                                <GridColumn width={10}>
                                    <Grid stackable>
                                        <GridRow>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_REGISTRATIONNUMBER)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleRegistrationNumber customerVehicle={job.data?.serviceData?.customerVehicleData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerVehicleData?.registrationNumber}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_VEHICLE_TYPE)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleVehicleModal customerVehicle={job.data?.serviceData?.customerVehicleData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{String()
                                                        .concat(job.data?.serviceData?.customerVehicleData?.vehicleData?.brand)
                                                        .concat(job.data?.serviceData?.customerVehicleData?.vehicleData?.model)
                                                        .concat(job.data?.serviceData?.customerVehicleData?.vehicleData?.series)
                                                        .toUpperCase()
                                                    }</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_ENGINE)}</h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerVehicleData?.vehicleData.engine}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_TRANSMISSION)}</h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerVehicleData?.vehicleData?.transmission}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={6}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerVehicleVinNumberModal customerVehicle={job.data?.serviceData?.customerVehicleData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerVehicleData?.vinNumber}</p>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            </GridRow>
        );
    }

    const renderCustomerInformation = () => {
        const address1=job.data?.serviceData?.customerData?.addressLine1?job.data.serviceData.customerData.addressLine1:''; 
        const address2=job.data?.serviceData?.customerData?.addressLine2?job.data.serviceData.customerData.addressLine2:''; 
        const postcode=job.data?.serviceData?.customerData?.postcode?job.data.serviceData.customerData.postcode:''; 
        const city=job.data?.serviceData?.customerData?.city?job.data.serviceData.customerData.city:''; 
        const state=job.data?.serviceData?.customerData?.state?.value?job.data.serviceData.customerData.state.value:''; 
        return(
            <GridRow>
                <GridColumn>
                    <Header textAlign="left">{t(Text.UI_JOB_DETAILS_CUSTOMERINFORMATION)+" "}</Header>
                    <Segment textAlign="left">
                        <Grid columns={2} stackable>
                            <GridRow>
                                <GridColumn verticalAlign="middle" width={6} >
                                    <Image src={job.data?.serviceData?.customerData?.picture} size="small"/>
                                </GridColumn>
                                <GridColumn width={10}>
                                    <Grid stackable>
                                        <GridRow>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_FIRSTNAME)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerFirstNameModal customer={job.data?.serviceData?.customerData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerData?.firstName}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={8}>
                                            <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_LASTNAME)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerLastNameModal customer={job.data?.serviceData?.customerData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerData?.lastName}</p>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_MOBILENUMBER)}
                                                    <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                        <UpdateCustomerMobileNumberModal customer={job.data?.serviceData?.customerData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                    </RoleAllowed>
                                                    </h4>
                                                    <p className="no-margin">{job.data?.serviceData?.customerData?.mobileNumber}</p>
                                                </div>
                                            </GridColumn>
                                            <GridColumn width={8}>
                                                <div>
                                                    <h4 className="no-margin">{t(Text.GENERIC_DATA_CUSTOMER_FULLADDRESS)}
                                                        <RoleAllowed rolesAllowed={[ROLE.ROLE_MANAGER,ROLE.ROLE_ADVISOR]}>
                                                            <UpdateCustomerAddressModal customer={job.data?.serviceData?.customerData} onUpdate={() => {dispatch(getJob({jobId:id}))}}/>
                                                        </RoleAllowed>
                                                    </h4>
                                                    <span className="no-margin">{address1 + (address1 === ''?'':', ')}</span>
                                                    <span className="no-margin">{address2 + (address2 === ''?'':', ')}</span>
                                                    <span className="no-margin">{postcode + (postcode === ''?'':', ')}</span>
                                                    <span className="no-margin">{city + (city === ''?'':', ')}</span>
                                                    <span className="no-margin">{state}</span>
                                                </div>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                </GridColumn>
            </GridRow>
        );
    }

    const showTab = () => 
    {   
        if(job.data && job.success)
        {
            switch (activeTab)
            {
                case "details":
                    return(
                        <>
                        {menu()}
                        <div style={{backgroundColor:"white"}}>
                            <Grid stackable padded>
                                <GridRow columns={4} textAlign="left">
                                    <GridColumn>
                                        <div>
                                            <span className="major">{t(Text.GENERIC_DATA_LASTCHANGEDON) + " : "}</span>
                                            <span className="minor">{job.data.lastModifiedDate.replaceAll("-","/")}</span>
                                        </div>
                                    </GridColumn>
                                    <GridColumn>
                                        <div>
                                            <span className="major">{t(Text.GENERIC_DATA_LASTCHANGEDBY) + " : "}</span>
                                            <span className="minor">{job.data.lastModifiedByName}</span>
                                        </div>
                                    </GridColumn>
                                    <VisibleCondition condition={checkIn && (job?.data?.jobStatus?.key === "CHECKED_IN" || job?.data?.jobStatus?.key === "STARTED")}>
                                        <GridColumn width={2}>
                                            <span className="major">{t(Text.GENERIC_DATA_CHECKIN)} : </span>
                                            <TimerFlip value={moment(checkIn?.timestamp)?.toDate()?.toISOString()} />
                                        </GridColumn>
                                    </VisibleCondition>
                                    <VisibleCondition condition={startJob && job?.data?.jobStatus?.key === "STARTED"}>
                                        <GridColumn width={2}>
                                            <span className="major">{t(Text.GENERIC_DATA_STARTJOB) + " : "}</span>
                                            <TimerFlip value={moment(startJob?.timestamp)?.toDate()?.toISOString()} />
                                        </GridColumn>
                                    </VisibleCondition>
                                </GridRow>
                            </Grid>
                        </div>
                        <Segment className="tab-simulate-fixed" loading={job.loading} basic>
                            <Grid stackable padded>
                                {renderJobInformation()}
                                {renderJobDetails()}
                                {renderJobProgress()}    
                                <GridRow>
                                    <RoleAllowed rolesAllowed={[ROLE.ROLE_ADVISOR,ROLE.ROLE_MANAGER]}>
                                        <GridColumn width={8}>
                                                <VisibleCondition condition={job.data?.serviceData}>
                                                    {renderCustomerInformation()}
                                                </VisibleCondition>
                                        </GridColumn>
                                    </RoleAllowed>      
                                    <RoleAllowed rolesAllowed={[ROLE.ROLE_ADVISOR,ROLE.ROLE_MANAGER,ROLE.ROLE_TECHNICIAN]}>
                                        <GridColumn width={8}>
                                            <RoleAllowed rolesAllowed={[ROLE.ROLE_ADVISOR,ROLE.ROLE_MANAGER,ROLE.ROLE_TECHNICIAN]}>
                                                    <VisibleCondition condition={job.data?.serviceData}>
                                                        {renderVehicleInformation()}
                                                    </VisibleCondition>
                                                </RoleAllowed>                                    
                                        </GridColumn>
                                    </RoleAllowed>   
                                </GridRow>     
                            </Grid>
                        </Segment>
                        </>
                    );
                case "quotation":
                    return(
                        <>
                            {menu()}
                            <Segment className="tab-simulate-fixed" basic>
                                <Quotation job={job?.data}/>
                            </Segment>
                        </>
                    );
                case "serviceHistory":
                    return(
                        <>
                            {menu()}
                            <JobServiceHistory registrationNumber={job.data.serviceData.customerVehicleData.registrationNumber} filtered={true}/>
                        </>
                    );
                case "attachments":
                    return(
                        <>
                            {menu()}
                            <JobAttachments />
                        </>
                    );
                case "incident":
                    return(
                        <>
                            {menu()}
                            <IncidentDetails job={job}/>
                        </>
                    );
                default:
                    return(
                        <Header>Error Generating Tab</Header>
                    );
            }
        }
        else
        {
            return(
                <Message error={job.error} content={job.errorMessage}></Message>
            )
        }
    }

    return (
        <Navigation headerText={t(Text.UI_JOB_DETAILS_HEADER)} rolesAllowed={[Roles.ROLE_TECHNICIAN,Roles.ROLE_ADVISOR,Roles.ROLE_MANAGER]}>
            {showTab()}
        </Navigation>
    );
}
