import "./Dashboard.css";
import Roles from "../../constants/Roles";
import Text from '../../constants/Text';
import PATH from '../../constants/Path';
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Header, Menu, MenuItem } from 'semantic-ui-react'
import { Navigation } from '..';
import { getConfirmedTodayBookings } from '../../redux-slice/ConfirmedTodayBookingsSlice'
import { getProfile } from "../../redux-slice/ProfileSlice";
import { getEmployeesList } from "../../redux-slice/EmployeesSlice";
import { getMyStartedJobs, getStartedJobs } from "../../redux-slice/StartedJobsSlice";
import { getCheckedInJobs, getMyCheckedInJobs } from "../../redux-slice/CheckedInJobsSlice";
import { getCompletedJobs, getMyCompletedJobs } from "../../redux-slice/CompletedJobsSlice";
import AllJobsDashboard from "./AllJobsDashboard";
import MyJobsDashboard from "./MyJobsDashboard";

export const DashboardContext = createContext();

export default function Dashboard() {
    const TAB_MY_JOBS = "MY_JOBS";
    const TAB_ALL_JOBS = "ALL_JOBS";

    const authentication = useSelector(state => state.authentication);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const profile = useSelector(state => state.profile);
    const [activeTab, setActiveTab] = useState(profile.data.employeePreferences?.defaultDashboardPage || TAB_MY_JOBS)

    const [activeSegments, setActiveSegments] = useState([0]);
    
    const toggleSegment = (index) => {
        const newActiveSegments = [...activeSegments];
        const currentIndexPosition = activeSegments.indexOf(index);

        if (newActiveSegments.includes(index)) {
            newActiveSegments.splice(currentIndexPosition, 1)
            setActiveSegments(newActiveSegments);
        }
        else {
            newActiveSegments.push(index)
            setActiveSegments(newActiveSegments);
        }
    }

    const refreshDashboardData = useCallback(() => {
        dispatch(getProfile());
        dispatch(getConfirmedTodayBookings());
        dispatch(getEmployeesList());
        if (activeTab === TAB_MY_JOBS) {
            dispatch(getMyCheckedInJobs());
            dispatch(getMyStartedJobs());
            dispatch(getMyCompletedJobs());
        } else if (activeTab === TAB_ALL_JOBS) {
            dispatch(getCheckedInJobs());
            dispatch(getStartedJobs());
            dispatch(getCompletedJobs());
        }
    }, [dispatch, activeTab]);

    useEffect(() => {
        if (!authentication.authenticated) {
            navigate(PATH.LOGIN_PAGE);
        }
        else {
            //START ON MOUNT DATA LOAD
            refreshDashboardData();
        }
        document.title = "Dashboard";
    }, [authentication.authenticated, navigate, refreshDashboardData]);

    const handleMenuClick = (event, data) => {
        event.preventDefault();
        setActiveTab(data.name);
    }

    const menu = () => {
        return(
            <Menu className="no-margin" style={{overflowX:'scroll',backgroundColor: '#eee'}} pointing secondary color="red">
                <MenuItem icon="list alternate outline" name={TAB_MY_JOBS} content="My Jobs" onClick={handleMenuClick} active={activeTab === TAB_MY_JOBS} />
                <MenuItem icon="file" name={TAB_ALL_JOBS} content="All Jobs" onClick={handleMenuClick} active={activeTab === TAB_ALL_JOBS} />
            </Menu>
        )
    }

    const showTab = () => {
        switch(activeTab) {
            case TAB_MY_JOBS: return(<MyJobsDashboard refreshDashboardData={refreshDashboardData} toggleSegment={toggleSegment} />)
            case TAB_ALL_JOBS: return(<AllJobsDashboard refreshDashboardData={refreshDashboardData} toggleSegment={toggleSegment} />)
            default: return(<Header>Error Generating Tab</Header>);
        }
    }

    return (
        <Navigation headerText={t(Text.UI_DASHBOARD_HEADER)} rolesAllowed={[Roles.ROLE_TECHNICIAN, Roles.ROLE_ADVISOR, Roles.ROLE_MANAGER]}>
            {menu()}
            {showTab()}
        </Navigation>
    );
}