import React, { useEffect, useState } from 'react'
import Text from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';
import PATH from '../../../constants/Path';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, FormDropdown, FormInput, FormTextArea, Grid, GridColumn, GridRow, Image, Message, Modal, ModalContent, ModalHeader, Segment } from "semantic-ui-react";
import { VisibleCondition } from '../..';
import { isDesktop, isMobile } from "react-device-detect";
import CustomerVehicleDropdown from '../../common/dropdown/CustomerVehicleDropdown';
import TagDropdown from '../../common/dropdown/TagDropdown';
import EmployeeDropdown from '../../common/dropdown/EmployeeDropdown';
import { DateInput } from 'semantic-ui-calendar-react-17';
import moment from 'moment';

export default function NewJobModal({refreshDashboardData}) {

    const authentication = useSelector(state => state.authentication)
    const navigate = useNavigate();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [timeOptionList, setTimeOptionList] = useState([]);

    const [customerVehicle, setCustomerVehicle] = useState(null);
    const [selectedServiceTechnicians, setSelectedServiceTechnicians] = useState([]);
    const [selectedServiceLeadTechnician, setSelectedServiceLeadTechnician] = useState();
    const [selectedTagRepair, setSelectedTagRepair] = useState([]);
    const [selectedTagMaintenance, setSelectedTagMaintenance] = useState([]);
    const [selectedOldPartStatus, setSelectedOldPartStatus] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);

    const oldPartStatusOptions = [
        {
            key: 'KEEP_FOR_CUSTOMER',
            text: t(Text.UI_JOB_MODAL_KEEP),
            value: 'KEEP_FOR_CUSTOMER'
        },
        {
            key: 'DISCARD',
            text: t(Text.UI_JOB_MODAL_DISCARD),
            value: 'DISCARD'
        }
    ]

    const handleCreateNewJobAction = async (event) => {
        setLoading(true);
        event.preventDefault();
        const createNewJob = Object.fromEntries(new FormData(event.target).entries());

        createNewJob.customerVehicle = customerVehicle?.id;
        createNewJob.serviceTechnicians = selectedServiceTechnicians;
        createNewJob.serviceLeadTechnician = selectedServiceLeadTechnician;
        createNewJob.repairTags = selectedTagRepair;
        createNewJob.maintenanceTags = selectedTagMaintenance;
        createNewJob.oldPartStatus = selectedOldPartStatus;
        createNewJob.expectedCollectionTime = moment(selectedDate + " " + selectedTime, "DD-M-yyyy hh:mm a").toISOString(true);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(createNewJob)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            refreshDashboardData();
            setLoading(false)
            closeNewJobModal();
            navigate(PATH.JOB_DETAILS_PAGE.replace(":id",body.id))
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const closeNewJobModal = () => {
        setError(false);
        setErrorMessage(null);
        setCustomerVehicle(null);
        setOpen(false);
    }

    const openNewJobModal = () => {
        setOpen(true);
    }

    const handleSelectCustomerVehicle = (event,data) => {
        var customerVehicle = data?.options?.filter(item => item.customervehicle.id === data?.value)[0].customervehicle;
        setCustomerVehicle(customerVehicle);
    }

    const handleCreateCustomerVehicle = (event,data) => {
        var customerVehicle = data;
        setCustomerVehicle(customerVehicle);
    }

    const handleClearSelectedCustomerVehicle = (event) => {
        setCustomerVehicle();
    }

    const handleSelectTagRepair = (event,data) => {
        event.preventDefault();
        setSelectedTagRepair(data.value);
    }

    const handleSelectTagMaintenance = (event,data) => {
        event.preventDefault();
        setSelectedTagMaintenance(data.value);
    }

    const handleSelectServiceLeadTechnician = (event,data) => {
        event.preventDefault();
        setSelectedServiceLeadTechnician(data.value);
    }

    const handleSelectServiceTechnicians = (event,data) => {
        event.preventDefault();
        setSelectedServiceTechnicians(data.value);
    }

    const handleSelectOldPartStatus = (event,data) => {
        event.preventDefault();
        setSelectedOldPartStatus({key:data.value});
    }

    const newJobModalButton = () => {
        return(
            <Button floated='right' icon='plus'/>
        )
    }

    useEffect(() => {
        const fetchAvailableBookingTimeList = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authentication.authorization
                },
            }

            const response = await fetch(ENDPOINT.BOOKING_ENDPOINT + "/availableBookingTime?date=" + moment().format("DD-M-yyyy"), options);
            const body = await response.json();


            var result = body.map(val => {
                var obj = {};
                obj['key'] = val;
                obj['text'] = val;
                obj['value'] = val;
                return obj;
            });

            setTimeOptionList(result);
        }

        fetchAvailableBookingTimeList();
    }, [authentication]);

    const renderCustomerInfo = () =>{
        return(
            <VisibleCondition condition={customerVehicle}>
                <Segment>
                    <Grid stackable padded>
                        <GridRow>
                            <GridColumn width={12}>
                                <div>
                                    <h4 className="no-margin">{"Customer Information"}</h4>
                                </div>
                            </GridColumn>
                            <GridColumn width={4} textAlign='right'>
                                <Button icon="close" onClick={handleClearSelectedCustomerVehicle} />
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={4}>
                                <div>
                                    <VisibleCondition condition={isDesktop}>
                                        <Image size='small' src={customerVehicle?.customerData?.picture}/>
                                    </VisibleCondition>
                                    <VisibleCondition condition={isMobile}>
                                        <Image size='tiny' src={customerVehicle?.customerData?.picture}/>
                                    </VisibleCondition>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"First Name"}</h4>
                                    <p className="no-margin">{customerVehicle?.customerData?.firstName}</p>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"Last Name"}</h4>
                                    <p className="no-margin">{customerVehicle?.customerData?.lastName}</p>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"Mobile Number"}</h4>
                                    <p className="no-margin">{customerVehicle?.customerData?.mobileNumber}</p>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <div>
                                    <h4 className="no-margin">{"Customer Vehicle Information"}</h4>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={4}>
                                <div>
                                    <VisibleCondition condition={isDesktop}>
                                        <Image size='small' src={customerVehicle?.vehicleData?.picture}/>
                                    </VisibleCondition>
                                    <VisibleCondition condition={isMobile}>
                                        <Image size='tiny' src={customerVehicle?.vehicleData?.picture}/>
                                    </VisibleCondition>
                                </div>
                            </GridColumn>
                            <GridColumn width={12}>
                                <Grid stackable>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Brand"}</h4>
                                                <p className="no-margin">{customerVehicle?.vehicleData?.brand}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Model"}</h4>
                                                <p className="no-margin">{customerVehicle?.vehicleData?.model}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Series"}</h4>
                                                <p className="no-margin">{customerVehicle?.vehicleData?.series}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Transmission"}</h4>
                                                <p className="no-margin">{customerVehicle?.vehicleData?.transmission}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Registration Number"}</h4>
                                                <p className="no-margin">{customerVehicle?.registrationNumber}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Vin Number"}</h4>
                                                <p className="no-margin">{customerVehicle?.vinNumber}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Customer Mileage"}</h4>
                                                <p className="no-margin">{customerVehicle?.customerMileage}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>   
            </VisibleCondition>
        )
    }

    return(
        <Modal centered closeIcon={true} open={open} onClose={closeNewJobModal} onOpen={openNewJobModal} trigger={newJobModalButton()} closeOnDimmerClick={false}>
            <ModalHeader>{t(Text.UI_JOB_MODAL_ADDJOB)}</ModalHeader>
            <ModalContent scrolling>
                <VisibleCondition condition={!customerVehicle}>
                    <CustomerVehicleDropdown handleSelectCustomerVehicle={handleSelectCustomerVehicle} handleCreateCustomerVehicle={handleCreateCustomerVehicle}/>
                </VisibleCondition>
                <Form onSubmit={handleCreateNewJobAction} loading={loading}>
                    {renderCustomerInfo()}
                    <EmployeeDropdown label={t(Text.GENERIC_DATA_JOB_SERVICELEADTECHNICIAN)} placeholder={t(Text.GENERIC_DATA_JOB_SERVICELEADTECHNICIAN)} required={true} multiple={false} handleSelectEmployee={handleSelectServiceLeadTechnician}/>
                    <EmployeeDropdown label={t(Text.GENERIC_DATA_JOB_SERVICETECHNICIANS)} placeholder={t(Text.GENERIC_DATA_JOB_SERVICETECHNICIANS)} required={false} multiple={true} handleSelectEmployee={handleSelectServiceTechnicians}/>
                    <FormInput label={t(Text.GENERIC_DATA_SERVICE_MILEAGE)} type="text" name="mileage" placeholder={t(Text.GENERIC_DATA_SERVICE_MILEAGE)}/>
                    <VisibleCondition condition={!customerVehicle?.vinNumber}>
                        <FormInput required label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} type="text" name="vinNumber" placeholder={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)}/>
                    </VisibleCondition>
                    <FormDropdown required label={t(Text.UI_JOB_DETAILS_OLDPARTSTATUS)} name="oldPartStatus" placeholder={t(Text.UI_JOB_MODAL_OLDPARTS)} fluid selection options={oldPartStatusOptions} onChange={handleSelectOldPartStatus}/>
                    <Grid>
                        <GridRow columns={2}>
                            <GridColumn>
                                <DateInput
                                    required
                                    name="expectedCollectionDate"
                                    label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_DATE)}
                                    placeholder={t(Text.GENERIC_SELECT_DATE)}
                                    iconPosition="left"
                                    dateFormat='DD-M-YYYY'
                                    minDate={moment()}
                                    value={selectedDate}
                                    onChange={(event, data) => setSelectedDate(data.value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <VisibleCondition condition={selectedDate !== ""}>
                                    <FormDropdown
                                        label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_TIME)}
                                        placeholder={t(Text.GENERIC_SELECT_TIME)}
                                        name="expectedCollectionTime"
                                        fluid
                                        search
                                        selection
                                        required
                                        options={timeOptionList}
                                        value={selectedTime}
                                        onChange={(event, data) => setSelectedTime(data.value)}
                                    />
                                </VisibleCondition>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                    <TagDropdown name={'Repair'} label={t(Text.GENERIC_DATA_TAG_REPAIR)} value={selectedTagRepair} category={'REPAIR'} placeholder={t(Text.GENERIC_DATA_TAG_REPAIR_PLACEHOLDER)} handleSelectTag={handleSelectTagRepair}/>
                    <TagDropdown name={'Maintenance'} label={t(Text.GENERIC_DATA_TAG_MAINTENANCE)} value={selectedTagMaintenance} category={'MAINTENANCE'} placeholder={t(Text.GENERIC_DATA_TAG_MAINTENANCE_PLACEHOLDER)} handleSelectTag={handleSelectTagMaintenance}/>
                    <FormTextArea label={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} type="text" name="customerRequest" placeholder={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)} />
                    <FormTextArea label={t(Text.UI_JOB_DETAILS_ABNORMALITIES)} type="text" name="abnormalities" placeholder={t(Text.UI_JOB_DETAILS_ABNORMALITIES)}/>
                    <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                    <Message className='support-line-break' error visible={error} header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage}/>
                </Form>
            </ModalContent>
        </Modal>
    )
}