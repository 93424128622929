import React, { useCallback, useEffect, useState } from 'react'
import ENDPOINT from '../../../constants/API';
import { useSelector } from 'react-redux';
import { Dropdown, FormDropdown } from "semantic-ui-react";
import VisibleCondition from '../VisibleCondition';

export default function ProductSupplierDropdown (props) {
    const authentication = useSelector(state => state.authentication);

    const [loading, setLoading] = useState(false);
    const [productSupplierOptions, setProductSupplierOptions] = useState([])

    const getProductSupplier = useCallback(async (event,value) => {
        setLoading(true);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT+"/suppliers",options)
        const body = await response.json();
        
        setProductSupplierOptions(body?body.map(item => {
            let container = {};
            container['value'] = item;
            container['text'] = item;
            return container;
        }):[]);
        setLoading(false);
    },[authentication.authorization]);

    useEffect(() => {
      getProductSupplier();  
    },[getProductSupplier])

    return (
        <>
            <VisibleCondition condition={props.form === true}>
                <FormDropdown multiple={props.multiple} clearable={props.clearable} width={props.width} compact={props.compact} required={props.required} value={props.value} label={props.label} loading={loading} placeholder={props.placeholder} fluid={props.fluid} selection search options={productSupplierOptions} onChange={props.handleSelectProductSupplier} />
            </VisibleCondition>
            <VisibleCondition condition={props.form === false}>
                <Dropdown icon={props.icon} multiple={props.multiple} value={null} clearable={props.clearable} compact={props.compact} options={productSupplierOptions} onChange={props.handleSelectProductSupplier} />
            </VisibleCondition>
        </>
    );
}