import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem, Modal, ModalContent, ModalHeader } from "semantic-ui-react";
import NewBookingByCustomerTab from "./NewBookingByCustomerTab";
import NewBookingByCarPlateTab from "./NewBookingByCarPlateTab";
import Text from "../../../constants/Text";
import VisibleCondition from "../../common/VisibleCondition";

export default function NewBookingModal({refreshDashboardData}) {
    const MENU_BOOK_BY_CUSTOMER_NAME_OR_MOBILE = "bookByCustomer";
    const MENU_BOOK_BY_CAR_PLATE_NUMBER = "bookByCarPlateNumber";

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(MENU_BOOK_BY_CAR_PLATE_NUMBER);

    const onSubmitComplete = () => {
        refreshDashboardData();
        setOpen(false);
    }

    return (
        <Modal centered closeIcon={true} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} trigger={<Button floated='right' icon='plus' />} closeOnDimmerClick={false}>
            <ModalHeader>{t(Text.UI_BOOKING_MODAL_ADD_BOOKING)}</ModalHeader>
            <ModalContent>
                <Menu className='no-margin' style={{ overflowX: 'scroll' }} pointing secondary color="red">
                    <MenuItem content='Book By Carplate Number' active={activeMenu === MENU_BOOK_BY_CAR_PLATE_NUMBER} onClick={() => setActiveMenu(MENU_BOOK_BY_CAR_PLATE_NUMBER)} />
                    <MenuItem content='Book By Customer Name or Mobile' active={activeMenu === MENU_BOOK_BY_CUSTOMER_NAME_OR_MOBILE} onClick={() => setActiveMenu(MENU_BOOK_BY_CUSTOMER_NAME_OR_MOBILE)} />
                </Menu>
                <div style={{ margin: 5, marginTop: 10 }}>
                    <VisibleCondition condition={activeMenu === MENU_BOOK_BY_CAR_PLATE_NUMBER}>
                        <NewBookingByCarPlateTab onSubmitComplete={onSubmitComplete} />
                    </VisibleCondition>
                    <VisibleCondition condition={activeMenu === MENU_BOOK_BY_CUSTOMER_NAME_OR_MOBILE}>
                        <NewBookingByCustomerTab onSubmitComplete={onSubmitComplete} />
                    </VisibleCondition>
                </div>
            </ModalContent>
        </Modal>
    );
}