import "./Dashboard.css";
import Roles from "../../constants/Roles";
import Text from '../../constants/Text';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardContent, Container, Grid, GridColumn, GridRow, Header, Icon, Label, Menu, MenuItem, Segment } from 'semantic-ui-react'
import { RoleAllowed, VisibleCondition } from '..';
import { convertDateTimeToDefaultTime } from '../utils/DateTimeFormatUtil';
import ClampLines from 'react-clamp-lines';
import BookingToJobModal from './BookingToJobModal';
import NewJobModal from "../job/new/NewJobModal";
import JobCard from "./JobCard";
import NewBookingModal from "../booking/modals/NewBookingModal";

export default function MyJobsDashboard({ refreshDashboardData }) {

    const confirmedTodayBookings = useSelector(state => state.confirmedTodayBookings);
    const startedJobs = useSelector(state => state.startedJobs);
    const checkedInJobs = useSelector(state => state.checkedInJobs);
    const completedJobs = useSelector(state => state.completedJobs);
    const { t } = useTranslation();
    const gridColumns = 4;

    const confirmedTodayBookingsList = confirmedTodayBookings.data;
    const startedJobsList = startedJobs.data;
    const checkedInJobsList = checkedInJobs.data;
    const completedJobsList = completedJobs.data;

    const [onGoingJobsLoading, setOnGoingJobLoading] = useState(false);

    useEffect(() => {
        !checkedInJobs.loading && !startedJobs.loading && !completedJobs.loading ? setOnGoingJobLoading(false) : setOnGoingJobLoading(true);
    }, [checkedInJobs.loading, startedJobs.loading, completedJobs.loading])

    const activeBookingsTitle = () => {
        return (
            <Menu secondary>
            <MenuItem>
                <Icon loading={confirmedTodayBookings.loading} name={confirmedTodayBookings.loading ? "notched circle" : "chevron circle down"}/>
                <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                    {t(Text.UI_DASHBOARD_BOOKING_HEADER)} - {confirmedTodayBookingsList ? confirmedTodayBookingsList.length : 0}
                </Header>
            </MenuItem>
            <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                <MenuItem position="right">
                    <NewBookingModal refreshDashboardData={refreshDashboardData} />
                </MenuItem>
            </RoleAllowed>
            </Menu>
        )
    }

    const checkedInJobsTitle = () => {

        const cil = checkedInJobsList ? checkedInJobsList.length : 0;
        const sl = startedJobsList ? startedJobsList.length : 0;
        const cl = completedJobsList ? completedJobsList.length : 0;
        const tl = cil + sl + cl;

        return (
            <>
                <Segment raised>
                    <Label size="large" as='a' color="red" ribbon attached="top">{t(Text.UI_DASHBOARD_ONGOINGJOB_HEADER)} - {tl} <VisibleCondition condition={onGoingJobsLoading}>&nbsp;&nbsp;<Icon name="notched circle" loading /></VisibleCondition></Label>
                </Segment>
                <Menu secondary>
                    <MenuItem>
                        <Icon loading={checkedInJobs.loading} name={checkedInJobs.loading ? "notched circle" : "chevron circle down"}/>
                        <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                            {t(Text.UI_DASHBOARD_CHECKEDINJOB_HEADER)} - {checkedInJobsList ? checkedInJobsList.length : 0}
                        </Header>
                    </MenuItem>
                    <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                        <MenuItem position="right">
                            <NewJobModal refreshDashboardData={refreshDashboardData} />
                        </MenuItem>
                    </RoleAllowed>
                </Menu>
            </>
        )
    }

    const startedJobsTitle = () => {
        return (
            <Menu secondary>
                <MenuItem>
                    <Icon loading={startedJobs.loading} name={startedJobs.loading ? "notched circle" : "chevron circle down"}/>
                    <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                        {t(Text.UI_DASHBOARD_STARTED_HEADER)} - {startedJobsList ? startedJobsList.length : 0}
                    </Header>
                </MenuItem>
            </Menu>
        )
    }

    const completedJobsTitle = () => {
        return (
            <Menu secondary>
                <MenuItem>
                    <Icon loading={completedJobs.loading} name={completedJobs.loading ? "notched circle" : "chevron circle down"}/>
                    <Header className="no-margin" style={{ paddingRight: 10 }} as='h3'>
                        {t(Text.UI_DASHBOARD_COMPLETEDJOB_HEADER)} - {completedJobsList ? completedJobsList.length : 0}
                    </Header>
                </MenuItem>
            </Menu>
        )
    }

    const renderConfirmedTodayBookings = (item) => {
        const bookings = item ? item : [];
        return bookings.map((booking, idx) => {
            const firstName = booking?.customerData?.firstName ? booking.customerData.firstName : '';
            const lastName = booking?.customerData?.lastName ? booking.customerData.lastName : '';
            return (
                <GridColumn key={idx} width={gridColumns}>
                    <Card color='red' fluid>
                        <Menu className="menu-header" secondary attached>
                            <MenuItem header className="menu-item-header" position="left" content={booking.registrationNumber} />
                            <MenuItem header className="menu-item-header" position="right" content={booking.status.value.toUpperCase()} />
                        </Menu>
                        <CardContent textAlign="left">
                            <Container>
                                <div>
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_BOOKING_BOOKINGTIME)}:</h4>
                                    <p className='no-margin'>{convertDateTimeToDefaultTime(booking.bookingDateTime)}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMERNAME)}:</h4>
                                    <p className='no-margin'>{firstName + " " + lastName}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_CUSTOMER_MOBILENUMBER)}:</h4>
                                    <p className='no-margin'>{booking?.customerData?.mobileNumber}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.GENERIC_DATA_VEHICLE_TYPE)}:</h4>
                                    <p className='no-margin'>{(booking.carBrand + booking.carModel + booking.carSeries).toUpperCase()}</p>
                                    <br />
                                    <h4 className='no-margin'>{t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}:</h4>
                                    <ClampLines text={booking.comments} id="default" lines={4} ellipsis="..." moreText="More" lessText="Less" />
                                </div>
                            </Container>
                        </CardContent>
                        <CardContent textAlign="right">
                            <BookingToJobModal booking={booking} refreshDashboardData={refreshDashboardData} />
                        </CardContent>
                    </Card>
                </GridColumn>
            )
        }
        )
    }

    const renderJobCards = (item) => {
        if (onGoingJobsLoading) {
            return;
        }

        const jobs = item ? item : [];
        return jobs.map((job, idx) => {
            return (
                <GridColumn textAlign="center" key={idx} width={gridColumns}>
                    <JobCard jobHeader={job} />
                </GridColumn>
            )
        });
    }

    return (
        <Segment style={{padding:"0 1em 0 1em"}} basic>
            <Grid stackable centered>
                <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                    <GridRow>
                        <GridColumn>
                            {activeBookingsTitle()}
                            <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderConfirmedTodayBookings(confirmedTodayBookingsList)} />
                        </GridColumn>
                    </GridRow>
                </RoleAllowed>
                <GridRow>
                    <GridColumn>
                        {checkedInJobsTitle()}
                        <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(checkedInJobsList)} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        {startedJobsTitle()}
                        <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(startedJobsList)} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        {completedJobsTitle()}
                        <Grid stackable doubling columns={gridColumns} style={{ margin: '0em' }} children={renderJobCards(completedJobsList)} />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    );
}
