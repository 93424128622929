import React, { useCallback, useEffect, useState } from 'react'
import ENDPOINT from '../../../constants/API';
import { useSelector } from 'react-redux';
import { Dropdown, FormDropdown } from "semantic-ui-react";
import VisibleCondition from '../VisibleCondition';

export default function ProductTypeDropdown ({icon,form,multiple,clearable,width,compact,required,value,label,placeholder,fluid,handleSelectProductType}) {
    const authentication = useSelector(state => state.authentication);

    const [loading, setLoading] = useState(false);
    const [productTypeOptions, setProductTypeOptions] = useState([])

    const getProductTypes = useCallback(async (event,value) => {
        setLoading(true);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.PRODUCT_ENDPOINT+"/types",options)
        const body = await response.json();
        
        setProductTypeOptions(body?body.map(item => {
            let container = {};
            container['value'] = item;
            container['text'] = item;
            return container;
        }):[]);
        setLoading(false);
    },[authentication.authorization]);

    useEffect(() => {
      getProductTypes();  
    },[getProductTypes])

    return (
        <>
            <VisibleCondition condition={form === true}>
                <FormDropdown multiple={multiple} clearable={clearable} width={width} compact={compact} required={required} value={value} label={label} loading={loading} placeholder={placeholder} fluid={fluid} selection search options={productTypeOptions} onChange={handleSelectProductType} />
            </VisibleCondition>
            <VisibleCondition condition={form === false}>
                <Dropdown icon={icon} multiple={multiple} clearable={clearable} compact={compact} value={null} options={productTypeOptions} onChange={handleSelectProductType} />
            </VisibleCondition>
        </>
    );
}
