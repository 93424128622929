
import React, { useEffect, useState } from 'react'
import Text from '../../../constants/Text.js'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibleCondition from '../../common/VisibleCondition.js';
import { Segment, GridRow, Image, Grid, GridColumn, Message, Form, FormDropdown, Button, FormTextArea, FormCheckbox } from 'semantic-ui-react';
import { BOOKING_ENDPOINT } from '../../../constants/API.js';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { isDesktop, isMobile } from "react-device-detect";
import moment from 'moment';
import { getConfirmedTodayBookings } from '../../../redux-slice/ConfirmedTodayBookingsSlice.js';
import CustomerVehicleDropdown from '../../common/dropdown/CustomerVehicleDropdown.js';
import { toast } from 'react-toastify';

export default function NewBookingModal({ onSubmitComplete }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication);

    const initialFormData = {
        customer: null,
        vehicle: null,
        registrationNumber: null,
        bookingDateTime: null
    }

    const [selectedCustomerVehicle, setSelectedCustomerVehicle] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);
    const [useCurrentDateTime, setUseCurrentDateTime] = useState(false);
    const [timeOptionList, setTimeOptionList] = useState([]);


    const [formData, setFormData] = useState(initialFormData);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleTimeSelection = (event, data) => {
        setSelectedTime(data.value);
        setFormData({
            ...formData,
            bookingDateTime: selectedDate + " " + data.value
        });
    }

    const submitNewBooking = async (event) => {
        var bodyData = formData;
        useCurrentDateTime ? bodyData.bookingDateTime = moment().toISOString(true) : bodyData.bookingDateTime = moment(formData.bookingDateTime, "DD-M-yyyy hh:mm a").toISOString(true);

        bodyData.customer = selectedCustomerVehicle?.customerData?.id;
        bodyData.vehicle = selectedCustomerVehicle?.vehicle;
        bodyData.registrationNumber = selectedCustomerVehicle?.registrationNumber;

        event.preventDefault();
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(bodyData)
        }

        const response = await fetch(BOOKING_ENDPOINT, options);
        const body = await response?.json();

        if (response.ok) {
            setLoading(false);
            onSubmitComplete();
            toast.success(t(Text.UI_BOOKING_CREATED_DESC, { customerName: selectedCustomerVehicle?.customerData?.firstName + " " + selectedCustomerVehicle?.customerData?.lastName }), {
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
            });
            dispatch(getConfirmedTodayBookings());
        }
        else {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const handleSelectCustomerVehicle = (event, data) => {
        var customerVehicle = data?.options?.filter(item => item.customervehicle.id === data?.value)[0].customervehicle;
        setSelectedCustomerVehicle(customerVehicle);
    }

    const handleCreateCustomerVehicle = (event, data) => {
        setSelectedCustomerVehicle(data);
    }

    useEffect(() => {
        const fetchAvailableBookingTimeList = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authentication.authorization
                },
            }

            const response = await fetch(BOOKING_ENDPOINT + "/availableBookingTime?date=" + moment().format("DD-M-yyyy"), options);
            const body = await response.json();


            var result = body.map(val => {
                var obj = {};
                obj['key'] = val;
                obj['text'] = val;
                obj['value'] = val;
                return obj;
            });

            setTimeOptionList(result);
        }

        fetchAvailableBookingTimeList();
    }, [authentication]);

    const renderCustomerInfo = () => {
        return (
            <VisibleCondition condition={selectedCustomerVehicle}>
                <Segment>
                    <Grid stackable padded>
                        <GridRow>
                            <GridColumn width={12}>
                                <div>
                                    <h4 className="no-margin">{"Customer Information"}</h4>
                                </div>
                            </GridColumn>
                            <GridColumn width={4} textAlign='right'>
                                <Button icon="close" onClick={() => setSelectedCustomerVehicle(null)} />
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={4}>
                                <div>
                                    <VisibleCondition condition={isDesktop}>
                                        <Image size='small' src={selectedCustomerVehicle?.customerData?.picture} />
                                    </VisibleCondition>
                                    <VisibleCondition condition={isMobile}>
                                        <Image size='tiny' src={selectedCustomerVehicle?.customerData?.picture} />
                                    </VisibleCondition>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"First Name"}</h4>
                                    <p className="no-margin">{selectedCustomerVehicle?.customerData?.firstName}</p>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"Last Name"}</h4>
                                    <p className="no-margin">{selectedCustomerVehicle?.customerData?.lastName}</p>
                                </div>
                            </GridColumn>
                            <GridColumn width={4}>
                                <div>
                                    <h4 className="no-margin">{"Mobile Number"}</h4>
                                    <p className="no-margin">{selectedCustomerVehicle?.customerData?.mobileNumber}</p>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <div>
                                    <h4 className="no-margin">{"Customer Vehicle Information"}</h4>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={4}>
                                <div>
                                    <VisibleCondition condition={isDesktop}>
                                        <Image size='small' src={selectedCustomerVehicle?.vehicleData?.picture} />
                                    </VisibleCondition>
                                    <VisibleCondition condition={isMobile}>
                                        <Image size='tiny' src={selectedCustomerVehicle?.vehicleData?.picture} />
                                    </VisibleCondition>
                                </div>
                            </GridColumn>
                            <GridColumn width={12}>
                                <Grid stackable>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Brand"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.brand}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Model"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.model}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Series"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.series}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Transmission"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.transmission}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Registration Number"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.registrationNumber}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Vin Number"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vinNumber}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Customer Mileage"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.customerMileage}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </VisibleCondition>
        )
    }

    return (
        <div>
            <VisibleCondition condition={!selectedCustomerVehicle}>
                <CustomerVehicleDropdown handleSelectCustomerVehicle={handleSelectCustomerVehicle} handleCreateCustomerVehicle={handleCreateCustomerVehicle} />
            </VisibleCondition>
            <Form onSubmit={submitNewBooking} loading={loading}>
                {renderCustomerInfo()}
                <FormCheckbox toggle label="Use current date and time for booking" checked={useCurrentDateTime} onChange={(e, data) => setUseCurrentDateTime(data.checked)} />
                <VisibleCondition condition={useCurrentDateTime === false}>
                    <Grid>
                        <GridRow columns={2}>
                            <GridColumn>
                                <DateInput
                                    required
                                    name="bookingDate"
                                    label={t(Text.UI_BOOKING_DATE)}
                                    placeholder={t(Text.GENERIC_SELECT_DATE)}
                                    iconPosition="left"
                                    dateFormat='DD-M-YYYY'
                                    minDate={moment()}
                                    value={selectedDate}
                                    onChange={(event, data) => setSelectedDate(data.value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <VisibleCondition condition={selectedDate !== ""}>
                                    <FormDropdown
                                        label={t(Text.UI_BOOKING_TIME)}
                                        placeholder={t(Text.GENERIC_SELECT_TIME)}
                                        name="bookingTime"
                                        fluid
                                        search
                                        selection
                                        required
                                        options={timeOptionList}
                                        value={selectedTime}
                                        onChange={handleTimeSelection}
                                    />
                                </VisibleCondition>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </VisibleCondition>
                <br />
                <FormTextArea
                    required
                    label={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}
                    type="text"
                    name="comments"
                    placeholder={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}
                    onChange={handleChange}
                />
                <Message
                    className='support-line-break'
                    error
                    visible={error}
                    content={errorMessage} />
                <Button
                    className="vebo-submit-button"
                    fluid
                    size='large'
                    type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
            </Form>
        </div>
    )
}