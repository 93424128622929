import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import TagDropdown from '../../common/dropdown/TagDropdown';

export default function UpdateJobTagsModal (props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedTag, setSelectedTag] = useState(props.defaultValue);

    const handleUpdateJobTagModal = async() => {
        setLoading(true);
        let data = {
            tags : selectedTag
        }

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body : JSON.stringify(data)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT + "/" + props.job?.id +"/"+ props.category + "/updateTags",options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            closeUpdateJobTagModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateJobTagModalButton = () => {
        return(
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit'/>
        )
    }

    const closeUpdateJobTagModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
        dispatch(getJob({jobId:props.job?.id}))
    }

    const openUpdateJobTagModal = () => {
        setOpen(true);
    }

    return(
        <Modal closeIcon open={open} onClose={closeUpdateJobTagModal} onOpen={openUpdateJobTagModal} trigger={updateJobTagModalButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobTagModal} error={error}>
                            <TagDropdown label={props.label} placeholder={props.placeholder} category={props.category} value={selectedTag} handleSelectTag={(event,data) => setSelectedTag(data.value)}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                        <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}