import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, Grid, GridRow, GridColumn, FormDropdown } from 'semantic-ui-react';
import { getJob } from "../../../redux-slice/JobSlice";
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';
import { DateInput } from 'semantic-ui-calendar-react-17';
import moment from 'moment';
import VisibleCondition from '../../common/VisibleCondition';

export default function UpdateExpectedCollectionTimeModal(props) {

    const authentication = useSelector(state => state.authentication)
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [timeOptionList, setTimeOptionList] = useState([]);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const handleUpdateJobTransactionNumberModal = async (event) => {
        setLoading(true);
        event.preventDefault();

        var requestBody = {}
        requestBody.expectedCollectionTime = moment(selectedDate + " " + selectedTime, "DD-M-yyyy hh:mm a").toISOString(true);


        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(requestBody)
        }

        const response = await fetch(ENDPOINT.JOB_ENDPOINT + "/" + props.job?.id + "/updateExpectedCollectionTime", options)
        const body = await response.json();

        if (response.ok) {
            setLoading(false)
            onModalClose();
            dispatch(getJob({ jobId: body.id }))
        }
        else {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const triggerButton = () => {
        return (
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit' />
        )
    }

    const onModalClose = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const onModalOpen = () => {
        setOpen(true);
    }


    useEffect(() => {
        const fetchAvailableBookingTimeList = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authentication.authorization
                },
            }

            const response = await fetch(ENDPOINT.BOOKING_ENDPOINT + "/availableBookingTime?date=" + moment().format("DD-M-yyyy"), options);
            const body = await response.json();


            var result = body.map(val => {
                var obj = {};
                obj['key'] = val;
                obj['text'] = val;
                obj['value'] = val;
                return obj;
            });

            setTimeOptionList(result);
        }

        fetchAvailableBookingTimeList();
    }, [authentication]);

    return (
        <Modal closeIcon open={open} onClose={onModalClose} onOpen={onModalOpen} trigger={triggerButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateJobTransactionNumberModal} error={error}>
                            <Grid>
                                <GridRow columns={2}>
                                    <GridColumn>
                                        <DateInput
                                            required
                                            name="expectedCollectionDate"
                                            label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_DATE)}
                                            placeholder={t(Text.GENERIC_SELECT_DATE)}
                                            iconPosition="left"
                                            dateFormat='DD-M-YYYY'
                                            minDate={moment()}
                                            value={selectedDate}
                                            onChange={(event, data) => setSelectedDate(data.value)}
                                        />
                                    </GridColumn>
                                    <GridColumn>
                                        <VisibleCondition condition={selectedDate !== null}>
                                            <FormDropdown
                                                label={t(Text.UI_JOB_DETAILS_EXPECTED_COLLECTION_TIME)}
                                                placeholder={t(Text.GENERIC_SELECT_TIME)}
                                                name="expectedCollectionTime"
                                                fluid
                                                search
                                                selection
                                                required
                                                options={timeOptionList}
                                                value={selectedTime}
                                                onChange={(event, data) => setSelectedTime(data.value)}
                                            />
                                        </VisibleCondition>
                                    </GridColumn>
                                </GridRow>
                            </Grid>                          
                            <br/>  
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}