import React, { useState } from 'react'
import Text from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, FormDropdown, Grid, GridColumn, GridRow } from "semantic-ui-react";
import { isMobile } from 'react-device-detect';
import NewCustomerModal from '../../customer/new/NewCustomerModal';

export default function CustomerDropdown (props) {
    const authentication = useSelector(state => state.authentication);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([])

    const handleOnSearchChange = async (event,value) => {
        setLoading(true);
        event.preventDefault();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(ENDPOINT.CUSTOMER_ENDPOINT+"?search="+value.searchQuery,options)
        const body = await response.json();
        
        setCustomerOptions(body?body.map(item => {
            let container = {};
            container['value'] = item.id;
            container['text'] = item.firstName + " " + item.lastName + " ("+item.mobileNumber+")";
            container['customer'] = item;
            return container;
        }):[]);
        setLoading(false);
    }

    return (
        <Form>
            <Grid>
                <GridRow columns={2}>
                    <GridColumn width={isMobile?13:14}>
                        <FormDropdown required label={t(Text.GENERIC_DATA_CUSTOMER_CUSTOMER)} loading={loading} placeholder={t(Text.UI_DROPDOWN_CUSTOMER_PLACEHOLDER)} fluid selection search options={customerOptions} onSearchChange={handleOnSearchChange} onChange={props.handleSelectCustomer} />
                    </GridColumn>
                    <GridColumn verticalAlign='bottom' textAlign='right' width={isMobile?3:2}>
                        <NewCustomerModal handleCreateCustomer={props.handleCreateCustomer}/>
                    </GridColumn>
                </GridRow>
            </Grid>
            <br/>
        </Form>
    );
}
