import React, { useCallback, useEffect, useState } from 'react'
import ENDPOINT from '../../../constants/API';
import { useSelector } from 'react-redux';
import { FormDropdown, Item, ItemContent, ItemHeader, ItemMeta } from "semantic-ui-react";

export default function ProductSupplierDropdown ({allowAdditions, handleSelectProduct, multiple, value, clearable, width, required, label, placeholder, fluid}) {
    const authentication = useSelector(state => state.authentication);

    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [products, setProducts] = useState([]);

    const getProducts = useCallback(async () => {
        setLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        var url = new URL(ENDPOINT.PRODUCT_ENDPOINT)

        url.searchParams.append("page", 0);
        url.searchParams.append("size", 20);
        url.searchParams.append("excludeBundle", true);
        if(searchValue)
        {
            url.searchParams.append("search", "%"+searchValue+"%");
        }

        const response = await fetch(url,options)
        const body = await response.json();

        if(response.ok)
        {
            let productOptions = body?.content ? body?.content?.map(item => {
                let container = {};
                container['key'] = item?.id
                container['value'] = item?.id;
                container['text'] = item?.name;
                container['object'] = item; 
                container['content'] = 
                    <Item>
                        <ItemHeader as={"h5"}>{item?.name}</ItemHeader>
                        <ItemContent>
                            <ItemMeta>{item?.sku}</ItemMeta>
                        </ItemContent>
                    </Item>
                return container;
            }):[]

            if(allowAdditions && productOptions.filter(n => n.key === value).length === 0){
                let container = {};
                container['key'] = value
                container['value'] = value;
                container['text'] = value;
                productOptions.push(container);
            }
            setProducts(productOptions);
        }
        setLoading(false);
    },[authentication.authorization, value, allowAdditions, searchValue]);

    useEffect(() => {
        let productOptions = [];
        let container = {};
        container['key'] = value
        container['value'] = value;
        container['text'] = value;
        productOptions.push(container);
        setProducts(productOptions);
    },[value])

    return (
        <FormDropdown onClick={getProducts} allowAdditions={allowAdditions} onChange={handleSelectProduct} multiple={multiple} clearable={clearable} width={width} required={required} value={value} label={label} placeholder={placeholder} fluid={fluid} loading={loading} options={products} onSearchChange={(event, data) => setSearchValue(data.searchQuery)} selection search />
    );
}