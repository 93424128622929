import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, FormSelect, Message, Modal, ModalContent, ModalDescription, Segment } from 'semantic-ui-react';
import ENDPOINT from '../../../constants/API';
import Text from '../../../constants/Text';

const options = [
    { key: 'YES', text: 'Yes', value: true },
    { key: 'NO', text: 'No', value: false },
]

export default function UpdateSubmitEInvoiceModal({ customer, submitEInvoiceInit, onUpdate }) {

    const authentication = useSelector(state => state.authentication)
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitEInvoice, setSubmitEInvoice] = useState(submitEInvoiceInit);

    const handleUpdateSubmitEInvoice = async (event) => {
        setLoading(true);
        event.preventDefault();

        const updateSubmitEInvoice = {
            submitEInvoice: submitEInvoice
        }

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(updateSubmitEInvoice)
        }
        const response = await fetch(ENDPOINT.CUSTOMER_ENDPOINT + "/" + customer?.id + "/updateSubmitEInvoice", options)
        const body = await response.json();

        if (response.ok) {
            setLoading(false)
            closeUpdateSubmitEInvoiceModal();
            onUpdate();
        }
        else {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const updateSubmitEInvoiceButton = () => {
        return (
            <Button className='button-margin' fluid={false} compact size='mini' icon='edit' />
        )
    }

    const closeUpdateSubmitEInvoiceModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
    }

    const openUpdateSubmitEInvoiceModal = () => {
        setOpen(true);
    }

    return (
        <Modal closeIcon open={open} onClose={closeUpdateSubmitEInvoiceModal} onOpen={openUpdateSubmitEInvoiceModal} trigger={updateSubmitEInvoiceButton()}>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <Form onSubmit={handleUpdateSubmitEInvoice} error={error}>
                            <FormSelect
                                fluid
                                label='submitEInvoice'
                                options={options}
                                placeholder='Submit E-Invoice'
                                name="submitEInvoice"
                                value={submitEInvoice}
                                onChange={(e, { value }) => setSubmitEInvoice(value)}
                            />
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>Submit E-Invoice</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    )
}