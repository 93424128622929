import React, { useCallback, useEffect, useState } from 'react'
import ENDPOINT from '../../../constants/API';
import { useSelector } from 'react-redux';
import { FormDropdown } from "semantic-ui-react";

export default function TagDropdown ({category, handleSelectTag, compact, required, label, name, placeholder, value}) {
    const authentication = useSelector(state => state.authentication);

    const [loading, setLoading] = useState(false);
    const [tagOptions, setTagOptions] = useState([])

    const getTags = useCallback(async () => {
        setLoading(true);
        
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        const searchURL = new URL(ENDPOINT.TAG_ENDPOINT+'/tags');
        searchURL.searchParams.append("category",category)

        const response = await fetch(searchURL,options)
        const body = await response.json();
        
        setTagOptions(body ? body.map(item => {
            let container = {};
            container['key'] = item.id;
            container['value'] = item.id;
            container['text'] = item.name;
            container['tag'] = item;
            return container;
        }):[]);
        setLoading(false);
    },[authentication.authorization, category]);

    useEffect(() => {
        getTags();
    },[getTags])

    return (
        <FormDropdown compact={compact} required={required} label={label} name={name} placeholder={placeholder} onChange={handleSelectTag} value={value ? value : []} onOpen={getTags} options={tagOptions} loading={loading} multiple fluid selection search clearable />
    );
}
