import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { JOB_ENDPOINT } from "../constants/API";

export const getStartedJobs = createAsyncThunk(
    'startedJobs/get',
    async (_data, thunkAPI) => {

      const searchURL = new URL(JOB_ENDPOINT+'/activeJobs')
      searchURL.searchParams.append("jobStatus","STARTED")

      const response1 = await fetch(searchURL,{
        method: 'GET',
            headers: new Headers({
            'Authorization': thunkAPI.getState().authentication.authorization
        }),
        redirect: 'follow'
      })

      searchURL.searchParams.set("jobStatus","ON_HOLD")

      const response2 = await fetch(searchURL,{
        method: 'GET',
            headers: new Headers({
            'Authorization': thunkAPI.getState().authentication.authorization
        }),
        redirect: 'follow'
      })
  
      if(!response1.ok){
        return thunkAPI.rejectWithValue((await response1.json()));
      }
      else if (!response2.ok){
        return thunkAPI.rejectWithValue((await response2.json()));
      }
      else{
        const result1 = await response1.json();
        const result2 = await response2.json();
        return result1.concat(result2);
      }
    }
)

export const getMyStartedJobs = createAsyncThunk(
  'startedJobs/get',
  async (_data, thunkAPI) => {

    const searchURL = new URL(JOB_ENDPOINT+'/activeJobs')
    searchURL.searchParams.append("jobStatus","STARTED")
    searchURL.searchParams.append("employeeId",thunkAPI.getState().profile.data?.employeeId)

    const response1 = await fetch(searchURL,{
      method: 'GET',
          headers: new Headers({
          'Authorization': thunkAPI.getState().authentication.authorization
      }),
      redirect: 'follow'
    })

    searchURL.searchParams.set("jobStatus","ON_HOLD")

    const response2 = await fetch(searchURL,{
      method: 'GET',
          headers: new Headers({
          'Authorization': thunkAPI.getState().authentication.authorization
      }),
      redirect: 'follow'
    })

    if(!response1.ok){
      return thunkAPI.rejectWithValue((await response1.json()));
    }
    else if (!response2.ok){
      return thunkAPI.rejectWithValue((await response2.json()));
    }
    else{
      const result1 = await response1.json();
      const result2 = await response2.json();
      return result1.concat(result2);
    }
  }
)

const StartedJobsSlice = createSlice({
    name:'startedJobs',
    initialState:{
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        //START GET STARTED JOB REDUCERS
        builder.addCase(getStartedJobs.pending, (state, _action) => {
            state.success = false;
            state.error = false;
            state.errorMessage = null;
            state.loading = true;
        })
        builder.addCase(getStartedJobs.fulfilled, (state, action) => {
            state.data = action.payload;
            state.success = true;
            state.error = false;
            state.errorMessage = null;
            state.loading = false;
          })
          builder.addCase(getStartedJobs.rejected, (state, action) => {
            state.data = null;
            state.success = false;
            state.error = true;
            state.errorMessage = action.payload?action.payload.message:"Unexpected Error Retrieving In Progress Jobs";
            state.loading = false;
          })
        //END GET STARTED JOB REDUCERS
    }
});

export const { resetSuccess } = StartedJobsSlice.actions;

export default StartedJobsSlice.reducer;