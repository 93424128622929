import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Form, Modal, ModalContent, ModalHeader, TextArea } from "semantic-ui-react";


export default function GenerateSupplierMessageModal ({quote, trigger}) {
    const profile = useSelector(state => state.profile);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState();
    const [allowedQuoteItemStatus] = useState(['IN_PROGRESS','QUOTED','ACCEPTED']);

    const openGenerateSupplierMessageModal = () => {
        setOpen(true);
    }
    
    const closeGenerateSupplierMessageModal = () => {
        setOpen(false);
    }

    const renderText = useCallback(() => {
        let suppliers = Array.from(new Set(quote?.lineItem?.filter(n => n.supplier && n.supplier !== "VB" && allowedQuoteItemStatus.includes(n.status)).map(n => n.supplier)))
        let text = "";

        suppliers.forEach(supplier => {
            text = text.concat("*******************************  Supplier : "+ supplier +"  *******************************\n\n")
            text = text.concat("Dear Supplier,").concat("\n\nThis is ").concat(profile.data?.firstName + " " + profile.data?.lastName).concat(", I would like to place an order on the following:\n\n");
            quote?.lineItem?.filter(n => !n.notEditable && allowedQuoteItemStatus.includes(n.status) && n.supplier === supplier).forEach((element,index) => {
                text = text.concat(index + 1 + ") ").concat(element.sku + " ").concat(element.name + " ").concat("(" + element.type + ") ").concat("RM " + element.costPrice + " ").concat("x" + element.quantity).concat("\n\n");
            });
            text = text.concat("Urgency : Urgent\n\n");
        })
        setText(text);
    },[quote, profile.data?.firstName, profile.data?.lastName, allowedQuoteItemStatus])

    useEffect(() => {
        renderText();
    },[renderText, quote])

    return (
        <>
            <Modal closeIcon="close" open={open} onClose={closeGenerateSupplierMessageModal} onOpen={openGenerateSupplierMessageModal} trigger={trigger} closeOnDimmerClick={false}>
                <ModalHeader>Supplier Request Text</ModalHeader>
                <ModalContent>
                    <Form>
                        <TextArea rows={20} value={text} onChange={(event, data) => setText(data.value)} />
                    </Form>
                </ModalContent>
            </Modal>
        </>
    );
}
