import { Button, Form, FormDropdown, FormTextArea, Message, Modal, ModalContent, ModalHeader, Segment } from "semantic-ui-react";
import Text from "../../../constants/Text";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JOB_ENDPOINT } from "../../../constants/API";
import { getJob } from "../../../redux-slice/JobSlice";

export default function AddMechanicInputModal(props) {
    const categoryOptions = [
        { key: "OBSERVATION", text: "Observation", value: "OBSERVATION" },
        { key: "SUGGESTION", text: "Suggestion", value: "SUGGESTION" },
        { key: "ACTION", text: "Action", value: "ACTION" },
        { key: "FAULT_CODE", text: "Fault Code", value: "FAULT_CODE" },
        { key: "TEST_DRIVE", text: "Test Drive", value: "TEST_DRIVE" },
        { key: "OTHERS", text: "Others", value: "OTHERS" }
    ];

    const DEFAULT_FORM_DATA = {
        category: "",
        description: ""
    }

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

    const handleAddMechanicInput = async (event) => {
        setLoading(true);
        event.preventDefault();

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(formData)
        }
        const response = await fetch(JOB_ENDPOINT + "/" + props.job?.id + "/mechanicInput", options)
        const body = await response.json();

        if (response.ok) {
            setLoading(false)
            dispatch(getJob({ jobId: body.id }))
            onClose();
        }
        else {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const onClose = () => {
        setOpen(false)
        setError(false)
        setErrorMessage(null)
        setFormData(DEFAULT_FORM_DATA)
    }

    return (
        <Modal centered closeIcon open={open} onClose={onClose} onOpen={() => setOpen(true)} trigger={<Button className='button-margin' fluid={false} compact size='mini' icon='add'/>} >
            <Segment basic loading={loading}>
                <ModalHeader>{t(Text.UI_JOB_MODAL_ADD_OBSERVATION_HEADER)}</ModalHeader>
                <ModalContent>
                    <Form onSubmit={handleAddMechanicInput} error={error}>
                        <FormDropdown
                            required
                            fluid 
                            selection 
                            search 
                            name="category"
                            label={t(Text.UI_JOB_MODAL_CATEGORY)}
                            options={categoryOptions}
                            placeholder={t(Text.UI_JOB_MODAL_CATEGORY)}
                            onChange={(e, data) => setFormData({ ...formData, category: data.value })}
                        />
                        <FormTextArea
                            required
                            name="description"
                            label={t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}
                            placeholder={t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}
                            onChange={(e, data) => setFormData({ ...formData, description: data.value })}
                        />
                        <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                        <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                    </Form>
                </ModalContent>
            </Segment>
        </Modal>
    )
}