import { Button, Form, FormDropdown, FormTextArea, Message, Modal, ModalContent, ModalHeader, Segment } from "semantic-ui-react";
import Text from "../../../constants/Text";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JOB_ENDPOINT } from "../../../constants/API";
import { getJob } from "../../../redux-slice/JobSlice";
import { toast } from "react-toastify";
import ENDPOINT from '../../../constants/API';
import VisibleCondition from "../../common/VisibleCondition";

const defaultTemplateOption = [
    { key: "NO_TEMPLATE", text: "No Template", value: "NO_TEMPLATE", templateContent: "" }
];

export default function AddMechanicInputModal({ jobId, vehicleName, trigger }) {
    const DEFAULT_FORM_DATA = {
        category: "",
        description: ""
    }

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const fetchTemplates = useCallback(async () => {
        if (!formData.category) {
            return;
        }

        setLoading(true);
        const searchURL = new URL(ENDPOINT.JOB_ENDPOINT + "/technicianInputTemplate");
        searchURL.searchParams.append("category", formData.category);

        const response = await fetch(searchURL, {
            method: 'GET',
            headers: new Headers({
                'Authorization': authentication.authorization
            }),
        });

        if (response.ok) {
            const body = await response.json();
            if (Object.keys(body).length > 0) {
                setTemplateOptions(
                    defaultTemplateOption.concat(
                        Object.entries(body).map(([key, value]) => ({
                            key: key.toUpperCase(),
                            text: key,
                            value: key.toUpperCase(),
                            templateContent: value
                        }))
                    )
                );
            } else {
                setTemplateOptions([]);
            }
        }
        setLoading(false);
    }, [authentication.authorization, formData.category]);

    const fetchTemplateCategories = useCallback(async () => {
        setLoading(true);
        const searchURL = new URL(ENDPOINT.JOB_ENDPOINT + "/technicianInputTemplateCategories");

        const response = await fetch(searchURL, {
            method: 'GET',
            headers: new Headers({
                'Authorization': authentication.authorization
            }),
        });

        if (response.ok) {
            const body = await response.json();
            if (Object.keys(body).length > 0) {
                setCategoryOptions(
                    Object.entries(body).map(([key, value]) => ({
                        key: key.toUpperCase(),
                        text: value,
                        value: key.toUpperCase(),
                        templateContent: value
                    }))
                );
            } else {
                setCategoryOptions([]);
            }
        }
        setLoading(false);
    }, [authentication.authorization]);


    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates, formData.category]);

    useEffect(() => {
        fetchTemplateCategories();
    }, [fetchTemplateCategories]);

    const handleAddMechanicInput = async (event) => {
        setLoading(true);
        event.preventDefault();

        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(formData)
        }
        const response = await fetch(JOB_ENDPOINT + "/" + jobId + "/mechanicInput", options)
        const body = await response.json();

        if (response.ok) {
            dispatch(getJob({ jobId: body.id }));
            const firstName = body?.serviceData?.customerVehicleData?.customerData?.firstName;
            const model = body?.serviceData?.customerVehicleData?.vehicleData?.model;
            const registrationNumber = body?.serviceData?.customerVehicleData?.registrationNumber;
            const toastDisplayName = firstName + ' ' + model + ' ' + registrationNumber;
            toast.success(t(Text.UI_JOB_TOAST_MECHANIC_INPUT_SUCCES, { vehicle: toastDisplayName }), {
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
            });
            onClose();
        }
        else {
            setError(true);
            setErrorMessage(body.message);
        }
        setLoading(false);
    }

    const onClose = () => {
        setOpen(false)
        setError(false)
        setErrorMessage(null)
        setFormData(DEFAULT_FORM_DATA)
    }

    return (
        <Modal centered closeIcon open={open} onClose={onClose} onOpen={() => setOpen(true)} trigger={trigger} >
            <ModalHeader>{t(Text.UI_JOB_MODAL_ADD_MECHANIC_INPUT, { vehicle: vehicleName })}</ModalHeader>
            <Segment basic loading={loading}>
                <ModalContent>
                    <Form onSubmit={handleAddMechanicInput} error={error}>
                        <FormDropdown
                            required
                            fluid
                            selection
                            search
                            name="category"
                            label={t(Text.UI_JOB_MODAL_CATEGORY)}
                            options={categoryOptions}
                            placeholder={t(Text.UI_JOB_MODAL_CATEGORY)}
                            onChange={(e, data) => setFormData({ ...formData, category: data.value })}
                        />
                        <VisibleCondition condition={formData.category && templateOptions.length > 0}>
                            <FormDropdown
                                fluid
                                selection
                                search
                                name="template"
                                label={t(Text.UI_JOB_MODAL_TEMPLATE)}
                                options={templateOptions}
                                placeholder={t(Text.UI_JOB_MODAL_TEMPLATE)}
                                onChange={(e, data) =>
                                    setFormData({ ...formData, description: templateOptions.find(option => option.value === data.value).templateContent })}
                            />
                        </VisibleCondition>
                        <FormTextArea
                            required
                            name="description"
                            label={t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}
                            placeholder={t(Text.UI_JOB_DETAILS_JOBDESCRIPTION)}
                            value={formData.description}
                            rows={8}
                            onChange={(e, data) => setFormData({ ...formData, description: data.value })}
                        />
                        <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                        <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                    </Form>
                </ModalContent>
            </Segment>
        </Modal>
    )
}