import React, { useState } from 'react'
import Text, { UI_JOB_MODAL_NEWCUSTOMERVEHICLE } from '../../../constants/Text';
import ENDPOINT from '../../../constants/API';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, Message, Modal, ModalContent, ModalDescription, Segment, FormInput, ModalHeader, Grid, GridColumn } from 'semantic-ui-react';
import VehicleDropdown from '../../common/dropdown/VehicleDropdown';
import CustomerDropdown from '../../common/dropdown/CustomerDropdown';
import VisibleCondition from "../../common/VisibleCondition";

export default function NewCustomerVehicleModal(props) {

    const authentication = useSelector(state => state.authentication)

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedVehicle, setSelectedVehicle] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState()

    const handleCreateNewCustomerVehicle = async(event) => {
        setLoading(true);
        event.preventDefault();
        const createNewCustomerVehicle = Object.fromEntries(new FormData(event.target).entries());

        createNewCustomerVehicle.customer = selectedCustomer?.id;
        createNewCustomerVehicle.vehicle = selectedVehicle?.id;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(createNewCustomerVehicle)
        }

        const response = await fetch(ENDPOINT.CUSTOMER_VEHICLE_ENDPOINT,options)
        const body = await response.json();

        if(response.ok)
        {
            setLoading(false)
            if(props.handleCreateCustomerVehicle !== null){
                props.handleCreateCustomerVehicle(event,body)
            }
            closeNewCustomerVehicleModal();
        }
        else
        {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    const newCustomerVehicleButton = () => {
        return(
            <Button icon='add'/>
        )
    }

    const closeNewCustomerVehicleModal = () => {
        setError(false);
        setErrorMessage(null);
        setOpen(false);
        setSelectedCustomer();
    }

    const openNewCustomerVehicleModal = () => {
        setOpen(true);
    }

    const handleSelectVehicle = (event,data) => {
        var vehicle = data?.options?.filter(item => item.vehicle.id === data?.value)[0].vehicle;
        setSelectedVehicle(vehicle);
    }

    const handleSelectCustomer = (event,data) => {
        var customer = data?.options?.filter(item => item.customer.id === data?.value)[0].customer;
        setSelectedCustomer(customer);
    }

    const handleCreateCustomer = (event,data) => {
        var customer = data;
        setSelectedCustomer(customer);
    }

    const handleClearSelectedCustomer = (event) => {
        setSelectedCustomer();
    }

    return(
        <Modal closeIcon open={open} onClose={closeNewCustomerVehicleModal} onOpen={openNewCustomerVehicleModal} trigger={newCustomerVehicleButton()} closeOnDimmerClick={false}>
            <ModalHeader>{t(UI_JOB_MODAL_NEWCUSTOMERVEHICLE)}</ModalHeader>
            <Segment basic loading={loading}>
                <ModalContent>
                    <ModalDescription>
                        <VisibleCondition condition={!selectedCustomer}>
                            <CustomerDropdown required={true} handleSelectCustomer={handleSelectCustomer} handleCreateCustomer={handleCreateCustomer}/>
                        </VisibleCondition>
                        <VisibleCondition condition={selectedCustomer}>
                            <Grid>
                                <GridColumn width={14}>{selectedCustomer?.firstName} {selectedCustomer?.lastName}</GridColumn>
                                <GridColumn textAlign='right' width={2}><Button icon="close" onClick={handleClearSelectedCustomer}/></GridColumn>
                            </Grid>
                            <br/>
                        </VisibleCondition>
                        <Form onSubmit={handleCreateNewCustomerVehicle} error={error}>
                            <VehicleDropdown required={true} handleSelectVehicle={handleSelectVehicle}/>
                            <FormInput required label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_REGISTRATIONNUMBER)} type="text" name="registrationNumber" placeholder={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_REGISTRATIONNUMBER)}/>
                            <FormInput required label={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)} type="text" name="vinNumber" placeholder={t(Text.GENERIC_DATA_CUSTOMERVEHICLE_VINNUMBER)}/>
                            <Button className="vebo-submit-button" fluid size='large' type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
                            <Message className='support-line-break' error header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />
                        </Form>
                    </ModalDescription>
                </ModalContent>
            </Segment>
        </Modal>
    );
}