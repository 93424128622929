
import React, { useEffect, useState } from 'react'
import Text from '../../../constants/Text.js'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibleCondition from '../../common/VisibleCondition.js';
import CustomerDropdown from '../../common/dropdown/CustomerDropdown.js';
import { Segment, GridRow, Image, Grid, GridColumn, FormInput, Message, Form, FormDropdown, Button, FormTextArea, FormCheckbox } from 'semantic-ui-react';
import VehicleDropdown from '../../common/dropdown/VehicleDropdown.js';
import { BOOKING_ENDPOINT, CUSTOMER_VEHICLE_ENDPOINT } from '../../../constants/API.js';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { isDesktop, isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
import moment from 'moment';
import { getConfirmedTodayBookings } from '../../../redux-slice/ConfirmedTodayBookingsSlice.js';

export default function NewBookingModal({ onSubmitComplete }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication);

    const NEW_CAR_PLATE_NUMBER = t(Text.UI_BOOKING_NEW_CAR_PLATE_NUMBER)
    const newCarSelectionObj = {
        key: NEW_CAR_PLATE_NUMBER,
        text: NEW_CAR_PLATE_NUMBER,
        value: NEW_CAR_PLATE_NUMBER
    }

    const initialFormData = {
        customer: null,
        vehicle: null,
        registrationNumber: null,
        bookingDateTime: null
    }

    const [customerCarplateDisplayList, setCustomerCarplateDisplayList] = useState([newCarSelectionObj]);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerVehicleDataList, setCustomerVehicleDataList] = useState([]);
    const [selectedCustomerVehicle, setSelectedCustomerVehicle] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);
    const [useCurrentDateTime, setUseCurrentDateTime] = useState(false);
    const [timeOptionList, setTimeOptionList] = useState([]);


    const [formData, setFormData] = useState(initialFormData);

    const [isNewCar, setIsNewCar] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSelectCustomer = (event, data) => {
        var customer = data?.options?.filter(item => item.customer.id === data?.value)[0].customer;
        setSelectedCustomer(customer)
        fetchCustomerCarPlateList(customer.id);
        setFormData({
            ...formData,
            customer: customer.id
        });
    }

    const clearSelectedCustomer = () => {
        setSelectedCustomer(null);
        setCustomerCarplateDisplayList([newCarSelectionObj]);
        setFormData({
            ...formData,
            customer: null
        });
        setCustomerVehicleDataList([]);
        setSelectedCustomerVehicle(null);
    }

    const handleCreateCustomer = (event, data) => {
        setSelectedCustomer(data);
        setFormData({
            ...formData,
            customer: data.id
        });
    }

    const handleSelectVehicle = (event, data) => {
        var vehicle = data?.options?.filter(item => item.vehicle.id === data?.value)[0].vehicle;
        setFormData({
            ...formData,
            vehicle: vehicle.id
        });
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleTimeSelection = (event, data) => {
        setSelectedTime(data.value);
        setFormData({
            ...formData,
            bookingDateTime: selectedDate + " " + data.value
        });
    }

    const handleCarRegNumChange = (event, data) => {
        if (data.value === NEW_CAR_PLATE_NUMBER) {
            setIsNewCar(true);
            setFormData({
                ...formData,
                registrationNumber: null
            });

            setSelectedCustomerVehicle(null);
        } else {
            setIsNewCar(false);
            setFormData({
                ...formData,
                registrationNumber: data.value
            });

            setSelectedCustomerVehicle(customerVehicleDataList.filter(item => item.registrationNumber === data.value)[0])
        }
    }

    const fetchCustomerCarPlateList = async (customerId) => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
        }

        const response = await fetch(CUSTOMER_VEHICLE_ENDPOINT + "/customer/" + customerId, options);
        const body = await response.json();

        var selectionList = [newCarSelectionObj];

        body.forEach(item => {
            selectionList.push({
                key: item.registrationNumber,
                value: item.registrationNumber,
                text: item.registrationNumber
            });
        });

        setCustomerVehicleDataList(body);
        setCustomerCarplateDisplayList(selectionList);
    }

    const submitNewBooking = async (event) => {
        var bodyData = formData;
        useCurrentDateTime ? bodyData.bookingDateTime = moment().toISOString(true) : bodyData.bookingDateTime = moment(formData.bookingDateTime, "DD-M-yyyy hh:mm a").toISOString(true);

        event.preventDefault();
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authentication.authorization
            },
            body: JSON.stringify(bodyData)
        }

        const response = await fetch(BOOKING_ENDPOINT, options);
        const body = await response?.json();

        if (response.ok) {
            setLoading(false);
            onSubmitComplete();
            toast.success(t(Text.UI_BOOKING_CREATED_DESC, { customerName: selectedCustomer?.firstName + " " + selectedCustomer?.lastName }), {
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
            });
            dispatch(getConfirmedTodayBookings());
        }
        else {
            setError(true)
            setErrorMessage(body.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        const fetchAvailableBookingTimeList = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authentication.authorization
                },
            }

            const response = await fetch(BOOKING_ENDPOINT + "/availableBookingTime?date=" + moment().format("DD-M-yyyy"), options);
            const body = await response.json();


            var result = body.map(val => {
                var obj = {};
                obj['key'] = val;
                obj['text'] = val;
                obj['value'] = val;
                return obj;
            });

            setTimeOptionList(result);
        }

        fetchAvailableBookingTimeList();
    }, [authentication]);

    const renderCustomerInfo = () => {
        return (
            <VisibleCondition condition={selectedCustomerVehicle}>
                <Segment>
                    <Grid stackable padded>
                        <GridRow>
                            <GridColumn>
                                <div>
                                    <h4 className="no-margin">{"Customer Vehicle Information"}</h4>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={4}>
                                <div>
                                    <VisibleCondition condition={isDesktop}>
                                        <Image size='small' src={selectedCustomerVehicle?.vehicleData?.picture} />
                                    </VisibleCondition>
                                    <VisibleCondition condition={isMobile}>
                                        <Image size='tiny' src={selectedCustomerVehicle?.vehicleData?.picture} />
                                    </VisibleCondition>
                                </div>
                            </GridColumn>
                            <GridColumn width={12}>
                                <Grid stackable>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Brand"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.brand}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Model"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.model}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Series"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.series}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Transmission"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vehicleData?.transmission}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={4}>
                                            <div>
                                                <h4 className="no-margin">{"Vin Number"}</h4>
                                                <p className="no-margin">{selectedCustomerVehicle?.vinNumber}</p>
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </VisibleCondition>
        )
    }

    return (
        <div>
            <VisibleCondition condition={formData.customer === null}>
                <CustomerDropdown required={true} handleSelectCustomer={handleSelectCustomer} handleCreateCustomer={handleCreateCustomer} />
            </VisibleCondition>
            <VisibleCondition condition={formData.customer !== null}>
                <Grid>
                    <GridColumn width={14}>{selectedCustomer?.firstName} {selectedCustomer?.lastName}</GridColumn>
                    <GridColumn textAlign='right' width={2}><Button icon="close" onClick={clearSelectedCustomer} /></GridColumn>
                </Grid>
                <br />
            </VisibleCondition>
            <Form onSubmit={submitNewBooking} loading={loading}>
                <FormDropdown
                    required
                    label={t(Text.UI_JOB_DETAILS_CARPLATENUMBER)}
                    loading={loading}
                    placeholder="Select customer car plate number"
                    fluid selection search options={customerCarplateDisplayList}
                    onSearchChange={() => { }}
                    onChange={handleCarRegNumChange} />
                {renderCustomerInfo()}
                <VisibleCondition condition={isNewCar === true}>
                    <FormInput
                        required
                        label={NEW_CAR_PLATE_NUMBER}
                        type="text"
                        name="registrationNumber"
                        placeholder="eg. NCE3030"
                        onChange={handleChange} />
                    <VehicleDropdown required={true} handleSelectVehicle={handleSelectVehicle} />
                </VisibleCondition>
                <FormCheckbox toggle label="Use current date and time for booking" checked={useCurrentDateTime} onChange={(e, data) => setUseCurrentDateTime(data.checked)} />
                <VisibleCondition condition={useCurrentDateTime === false}>
                    <Grid>
                        <GridRow columns={2}>
                            <GridColumn>
                                <DateInput
                                    required
                                    name="bookingDate"
                                    label={t(Text.UI_BOOKING_DATE)}
                                    placeholder={t(Text.GENERIC_SELECT_DATE)}
                                    iconPosition="left"
                                    dateFormat='DD-M-YYYY'
                                    minDate={moment()}
                                    value={selectedDate}
                                    onChange={(event, data) => setSelectedDate(data.value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <VisibleCondition condition={selectedDate !== ""}>
                                    <FormDropdown
                                        label={t(Text.UI_BOOKING_TIME)}
                                        placeholder={t(Text.GENERIC_SELECT_TIME)}
                                        name="bookingTime"
                                        fluid
                                        search
                                        selection
                                        required
                                        options={timeOptionList}
                                        value={selectedTime}
                                        onChange={handleTimeSelection}
                                    />
                                </VisibleCondition>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </VisibleCondition>
                <br />
                <FormTextArea
                    required
                    label={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}
                    type="text"
                    name="comments"
                    placeholder={t(Text.UI_JOB_DETAILS_CUSTOMERREQUESTS)}
                    onChange={handleChange}
                />
                <Message
                    className='support-line-break'
                    error
                    visible={error}
                    content={errorMessage} />
                <Button
                    className="vebo-submit-button"
                    fluid
                    size='large'
                    type='submit'>{t(Text.GENERIC_SUBMIT)}</Button>
            </Form>
        </div>
    )
}