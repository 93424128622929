import { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Form, FormTextArea, Grid, GridColumn, GridRow, Header, Icon, Input, Label, Loader, Table, TableBody, TableCell, TableFooter, TableHeader, TableHeaderCell, TableRow, TextArea } from "semantic-ui-react";
import { QUOTE_ENDPOINT } from "../../constants/API";
import { useSelector } from "react-redux";
import { Autosave } from "react-autosave";
import VisibleCondition from '../common/VisibleCondition';
import ProductSelectorModal from "./ProductSelectorModal";
import ProductTypeDropdown from "../common/dropdown/ProductTypeDropdown";
import ProductSupplierDropdown from "../common/dropdown/ProductSupplierDropdown";
import GenerateSupplierMessageModal from "./GenerateSupplierMessageModal";
import RoleAllowed from "../common/RoleAllowed";
import Roles from "../../constants/Roles";
import { isDesktop, isMobile, isTablet } from "react-device-detect";

export default function Quotation({job}){

    const authentication = useSelector(state => state.authentication);
    const commentMaxLength = 2000;

    const [loadingQuote, setLoadingQuote] = useState(false);
    const [loadingAddLine, setLoadingAddLine] = useState(false);
    const [loadingDeleteLine, setLoadingDeleteLine] = useState(false);
    const [loadingUpdateQuote, setLoadingUpdateQuote] = useState(false);
    const [loadingPdfGeneration, setLoadPdfGeneration] = useState(false);

    const [error, setError] = useState(false)
    const [statusLabel, setStatusLabel] = useState(null)

    const [activeQuote, setActiveQuote] = useState(null);
    const [quoteToUpdate, setQuoteToUpdate] = useState(null);
    const [changesMade, setChangesMade] = useState(false);

    const [allowedQuoteItemStatus] = useState(['IN_PROGRESS','QUOTED','ACCEPTED','WAITING_FOR_PART']);

    const stockOptions = [
        {
            key: "CHK",
            value: "CHK",
            text: "CHECK"
        },
        {
            key: "YES",
            value: "YES",
            text: "YES"
        },
        {
            key: "NO",
            value: "NO",
            text: "NO"
        }
    ]

    const statusOptions = [
        {
            key: "IN_PROGRESS",
            value: "IN_PROGRESS",
            text: "IN PROGRESS"
        },
        {
            key: "QUOTED",
            value: "QUOTED",
            text: "QUOTED"
        },
        {
            key: "ACCEPTED",
            value: "ACCEPTED",
            text: "ACCEPTED"
        },
        {
            key: "REJECTED",
            value: "REJECTED",
            text: "REJECTED"
        },
        {
            key: "RETURN_PART",
            value: "RETURN_PART",
            text: "RETURN PART"
        },
        {
            key: "WAITING_FOR_PART",
            value: "WAITING_FOR_PART",
            text: "WAITING FOR PART"
        },
        {
            key: "OPTIONAL",
            value: "OPTIONAL",
            text: "OPTIONAL"
        }
    ]

    const readOnlyJobStatus = ["COMPLETED","CHECKED_OUT","CANCELLED"];

    const getQuote = useCallback(async () => {
        try{
            setLoadingQuote(true)
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization': authentication.authorization
                }
            }
            const response = await fetch(QUOTE_ENDPOINT+"/job/"+job?.id,options)
            const body = await response.json();
            if(response.ok)
            {
                setActiveQuote(body);
                setChangesMade(false);
            }
            setLoadingQuote(false)
        }
        catch(error){
            console.log("Warning Error " + error.name)
        }
    },[authentication,job?.id])

    const getPdf = useCallback(async () => {
        setLoadPdfGeneration(true);
        setStatusLabel("Generating Quote")
        try{
            const options = {
                method: 'GET',
                headers: {
                    'Authorization': authentication.authorization
                }
            }
            const response = await fetch(QUOTE_ENDPOINT+"/pdf/"+activeQuote?.id,options)

            if(response.ok)
            {
                const blob = await response.blob();

                const contentDisposition = response.headers.get('Content-Disposition');
                const filenameRegex = /filename=(?:.*?("|')?)([^"']+)(?:("|')?)/i;
                const filenameMatch = filenameRegex.exec(contentDisposition);
                const filename = filenameMatch ? filenameMatch[2] : 'quote.pdf';

                const pdfUrl = URL.createObjectURL(blob, {type: 'application/pdf'});
                const a = document.createElement('a');
                a.href = pdfUrl;
                a.download = filename;
                a.click();

                URL.revokeObjectURL(pdfUrl);
                setStatusLabel("Quote Downloaded")
            }
            else{
                const body = await response.json();
                setError(true)
                setStatusLabel(" Error Generating Quote (" + body?.message + ")")
            }
        }
        catch(error){
            console.log("Warning Error " + error.name)
        }
        setLoadPdfGeneration(false);
    },[authentication,activeQuote?.id])

    const createQuote = useCallback(async () => {
        setLoadingQuote(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(QUOTE_ENDPOINT+"/job/"+job?.id,options)
        const body = await response.json();
        if(response.ok)
        {
            setActiveQuote(body)
        }
        setLoadingQuote(false);
    },[authentication,job?.id])

    const addLine = useCallback(async () => {
        setLoadingAddLine(true)
        const options = {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(QUOTE_ENDPOINT+"/"+activeQuote?.id+"/addNewLineItem",options)
        const body = await response.json();
        if(response.ok)
        {
            setActiveQuote(body);
            setChangesMade(false);
        }
        setLoadingAddLine(false)
    },[authentication, activeQuote?.id])

    const deleteLine = useCallback(async (id) => {
        setLoadingDeleteLine(true)
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }
        const response = await fetch(QUOTE_ENDPOINT+"/"+activeQuote?.id+"/removeLineItem/"+id,options)
        const body = await response.json();
        if(response.ok)
        {
            setActiveQuote(body);
            setChangesMade(false);
        }
        setLoadingDeleteLine(false)
    },[authentication, activeQuote?.id])

    const updateQuote = useCallback(async () => {
        if(activeQuote)
        {
            setError(false)
            setLoadingUpdateQuote(true)
            setStatusLabel("Updating Quote")
            const options = {
                method: 'PATCH',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization': authentication.authorization
                },
                body: JSON.stringify(activeQuote)
            }
            const response = await fetch(QUOTE_ENDPOINT+"/"+activeQuote?.id,options)
            const body = await response.json();
            if(response.ok)
            {
                setActiveQuote(body)
                setChangesMade(false);
                setStatusLabel("Quote Updated (" + new Date().toLocaleString() + ")")
            }
            else{
                setError(true)
                setStatusLabel(" Error Updating (" + body?.message + ")")
            }
            setLoadingUpdateQuote(false)
        }
    },[authentication, activeQuote])

    const updateLineItemValue = (id, field, data) => {
        let lineItem = [...activeQuote?.lineItem];
        const val = lineItem.find(n => n.id === id);
        val[field] = data;
        
        let quote = {...activeQuote};
        quote.lineItem = lineItem;

        setActiveQuote(quote);
        setQuoteToUpdate(quote);

        setChangesMade(true)
    }

    const updateAllLineItemValue = (field, data) => {
        if(data){
            let lineItem = [...activeQuote?.lineItem];
            lineItem.forEach(n => {
                if(!n?.notEditable || ["stock", "status"].includes(field)) {
                    n[field] = data;
                }
            });
            
            let quote = {...activeQuote};
            quote.lineItem = lineItem;

            if(field === "status"){
                quote.serviceLabourStatus = data;
                quote.diagnosticStatus = data;
            }

            setActiveQuote(quote);
            setQuoteToUpdate(quote);

            setChangesMade(true)
        }
    }

    useEffect(() => {
        getQuote();
    },[getQuote])

    const printPDF = () => {
        getPdf();
    }

    const totalCost = () => {
        let total = 0;
        activeQuote?.lineItem?.filter(n => allowedQuoteItemStatus.includes(n.status)).forEach(n => {
            total = total + (n?.costPrice * n.quantity);
        })
        return total;
    }

    function roundTo(value, roundTo){
        return value?.toLocaleString("en-US", { maximumFractionDigits: roundTo, minimumFractionDigits: roundTo, useGrouping: false});
    }

    return(
        <div>
            <Loader active={loadingQuote} />
            <Grid padded>
                <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                    <VisibleCondition condition={activeQuote && !loadingQuote && isDesktop}>
                        <GridRow columns={2}>
                            <GridColumn textAlign="left">
                                <VisibleCondition condition={statusLabel}>
                                    <Label className='support-line-break' icon={<Icon name={loadingUpdateQuote || loadingPdfGeneration?"sync":error?"close":"check"} color={loadingUpdateQuote || loadingPdfGeneration?"yellow":error?"red":"green"} loading={loadingUpdateQuote || loadingPdfGeneration}/>} content={statusLabel} />
                                </VisibleCondition>
                            </GridColumn>
                            <GridColumn>
                                <ButtonGroup floated="right">
                                    <ProductSelectorModal trigger={<Button disabled={readOnlyJobStatus.includes(job?.jobStatus?.key)} icon='cog' content="Products" color='black'/>} quotationId={activeQuote?.id} onAfterClose={getQuote}/>
                                    <GenerateSupplierMessageModal quote={activeQuote} trigger={<Button disabled={changesMade} icon='truck' content="Supplier Order" color='yellow'/>} />
                                    <Button disabled={changesMade} loading={loadingPdfGeneration} icon="file pdf outline" color="red" content="Generate Quotation" onClick={() => printPDF()}/>
                                    <Button icon="save" color="green" content="Save" disabled={!quoteToUpdate || readOnlyJobStatus.includes(job?.jobStatus?.key)} onClick={() => updateQuote()} />
                                </ButtonGroup>
                            </GridColumn>
                        </GridRow>
                    </VisibleCondition>
                </RoleAllowed>
                <VisibleCondition condition={activeQuote}>
                    <GridRow>
                        <VisibleCondition condition={isDesktop}>
                            <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR]}>
                                <Table compact color="red" celled textAlign="center" unstackable structured>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell content={<>No</>}/>
                                            <TableHeaderCell width={2} content={<>SKU</>}/>
                                            <TableHeaderCell width={2} content={<>Name</>}/>
                                            <TableHeaderCell content={<>Type {readOnlyJobStatus.includes(job?.jobStatus?.key) ? null : <ProductTypeDropdown compact={true} form={false} icon={"caret down"} handleSelectProductType={(event,data) => updateAllLineItemValue("type", data.value)} />}</>}/>
                                            <TableHeaderCell width={2} content={<>Cost Price</>}/>
                                            <TableHeaderCell width={1} content={<>Qty</>}/>
                                            <TableHeaderCell content={<>Stock {readOnlyJobStatus.includes(job?.jobStatus?.key) ? null : <Dropdown options={stockOptions} value={null} icon={"caret down"} onChange={(event,data) => updateAllLineItemValue("stock", data.value)}/>}</>}/>
                                            <TableHeaderCell content={<>Supplier {readOnlyJobStatus.includes(job?.jobStatus?.key) ? null : <ProductSupplierDropdown compact={true} form={false} icon={"caret down"} handleSelectProductSupplier={(event,data) => updateAllLineItemValue("supplier", data.value)}/>}</>}/>
                                            <TableHeaderCell width={2} content={<>Add-On Price</>}/>
                                            <TableHeaderCell content={<>Customer Price</>}/>
                                            <TableHeaderCell content={<>Status {readOnlyJobStatus.includes(job?.jobStatus?.key) ? null : <Dropdown options={statusOptions} value={null} icon={"caret down"} onChange={(event,data) => updateAllLineItemValue("status", data.value)}/>}</>}/>
                                            <TableHeaderCell content={<>Action</>} />
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            activeQuote?.lineItem?.map((n,index) => n?.notEditable || readOnlyJobStatus.includes(job?.jobStatus?.key) ?
                                            <TableRow negative={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(n?.status)} positive={["ACCEPTED"].includes(n?.status)} key={n.id}>
                                                <TableCell content={<>{index + 1}</>}/>
                                                <TableCell content={n.sku}/>
                                                <TableCell content={n.name}/>
                                                <TableCell content={n.type}/>
                                                <TableCell content={"RM " + parseFloat(n.costPrice)?.toFixed(2)}/>
                                                <TableCell content={n.quantity}/>
                                                <TableCell content={readOnlyJobStatus.includes(job?.jobStatus?.key) ? n.stock : <Dropdown options={stockOptions} icon={"caret down"} value={n.stock} onChange={(event,data) => updateLineItemValue(n.id, "stock", data.value)}/>}/>
                                                <TableCell content={n.supplier}/>
                                                <TableCell content={"RM " + parseFloat(n.addOnPrice)?.toFixed(2)}/>
                                                <TableCell content={"RM " + parseFloat(n.customerPrice)?.toFixed(2)}/>
                                                <TableCell content={readOnlyJobStatus.includes(job?.jobStatus?.key) ? n.status : <Dropdown options={statusOptions} icon={"caret down"} value={n.status} onChange={(event,data) => updateLineItemValue(n.id, "status", data.value)}/>}/>
                                                <TableCell content={<Button loading={loadingDeleteLine} disabled={loadingDeleteLine || changesMade || readOnlyJobStatus.includes(job?.jobStatus?.key)} icon={"trash"} onClick={() => deleteLine(n?.id)}/>}/>
                                            </TableRow>
                                            : n?.fixedPrice ? 
                                                <TableRow negative={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(n?.status)} positive={["ACCEPTED"].includes(n?.status)} key={n.id}>
                                                <TableCell content={<>{index + 1}</>}/>
                                                <TableCell content={n.sku}/>
                                                <TableCell content={n.name}/>
                                                <TableCell content={n.type}/>
                                                <TableCell content={"RM " + parseFloat(n.costPrice)?.toFixed(2)}/>
                                                <TableCell content={<Input min={1} type="number" fluid value={n.quantity} onChange={(event,data) => updateLineItemValue(n.id, "quantity", data.value)} />}/>
                                                <TableCell content={<Dropdown options={stockOptions} icon={"caret down"} value={n.stock} onChange={(event,data) => updateLineItemValue(n.id, "stock", data.value)}/>}/>
                                                <TableCell content={n.supplier}/>
                                                <TableCell content={"RM " + parseFloat(n.addOnPrice)?.toFixed(2)}/>
                                                <TableCell content={"RM " + parseFloat(n.customerPrice)?.toFixed(2)}/>
                                                <TableCell content={readOnlyJobStatus.includes(job?.jobStatus?.key) ? n.status : <Dropdown options={statusOptions} icon={"caret down"} value={n.status} onChange={(event,data) => updateLineItemValue(n.id, "status", data.value)}/>}/>
                                                <TableCell content={<Button loading={loadingDeleteLine} disabled={loadingDeleteLine || changesMade || readOnlyJobStatus.includes(job?.jobStatus?.key)} icon={"trash"} onClick={() => deleteLine(n?.id)}/>}/>
                                            </TableRow>
                                            :
                                            <TableRow negative={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(n?.status)} positive={["ACCEPTED"].includes(n?.status)} key={n.id}>
                                                <TableCell content={<>{index + 1}</>}/>
                                                <TableCell content={<Input fluid value={n.sku ? n.sku : ""} onChange={(event,data) => updateLineItemValue(n.id, "sku", data.value)}/>}/>
                                                <TableCell content={<Form><TextArea rows={2} value={n.name ? n.name : ""} onChange={(event,data) => updateLineItemValue(n.id, "name", data.value)}/></Form>}/>
                                                <TableCell content={<ProductTypeDropdown compact={true} form={true} clearable={false} icon={"caret down"} value={n.type} handleSelectProductType={(event,data) => updateLineItemValue(n.id, "type", data.value)}/>}/>
                                                <TableCell content={<Input label="RM" type="number" fluid value={roundTo(n.costPrice,2)} onChange={(event,data) => updateLineItemValue(n.id, "costPrice", roundTo(data.value,2))} />}/>
                                                <TableCell content={<Input min={1} type="number" fluid value={n.quantity} onChange={(event,data) => updateLineItemValue(n.id, "quantity", data.value)} />}/>
                                                <TableCell content={<Dropdown options={stockOptions} icon={"caret down"} value={n.stock} onChange={(event,data) => updateLineItemValue(n.id, "stock", data.value)}/>}/>
                                                <TableCell content={<ProductSupplierDropdown compact={true} form={true} clearable={false} icon={"caret down"} value={n.supplier} handleSelectProductSupplier={(event,data) => updateLineItemValue(n.id, "supplier", data.value)}/>}/>
                                                <TableCell content={<Input label="RM" type="number" fluid value={roundTo(n.addOnPrice,2)} onChange={(event,data) => updateLineItemValue(n.id, "addOnPrice", roundTo(data.value))} />}/>
                                                <TableCell content={"RM " + parseFloat(n.customerPrice)?.toFixed(2)}/>
                                                <TableCell content={<Dropdown options={statusOptions} icon={"caret down"} value={n.status} onChange={(event,data) => updateLineItemValue(n.id, "status", data.value)}/>}/>
                                                <TableCell content={<Button loading={loadingDeleteLine} disabled={loadingDeleteLine || changesMade || readOnlyJobStatus.includes(job?.jobStatus?.key)} icon={"trash"} onClick={() => deleteLine(n?.id)}/>}/>
                                            </TableRow>)
                                        }
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <Button color="black" loading={loadingAddLine} disabled={loadingAddLine || changesMade || readOnlyJobStatus.includes(job?.jobStatus?.key)} icon="plus" basic fluid content={"Add New Line"} onClick={() => addLine()}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableHeaderCell textAlign="left" colSpan={8} rowSpan={5} content={
                                                <Form>
                                                    <FormTextArea disabled={readOnlyJobStatus.includes(job?.jobStatus?.key)} maxLength={commentMaxLength} label={"Terms & Conditions"} rows={11} defaultValue={activeQuote?.comment} onChange={(event, data) => {
                                                        let quote = structuredClone(activeQuote);
                                                        quote.comment = data.value;
                                                        setChangesMade(true);
                                                        setActiveQuote(quote);
                                                        setQuoteToUpdate(quote);
                                                    }}/>
                                                    <p style={{textAlign:"right"}}>{"Character Count ("+activeQuote?.comment?.length+"/"+commentMaxLength+")"}</p>
                                                </Form>
                                            }/>
                                            <TableHeaderCell content={<><Header textAlign="right" as={"h5"}>Service Labour</Header></>}/>
                                            <TableHeaderCell colSpan={2} content={<Input disabled={readOnlyJobStatus.includes(job?.jobStatus?.key)} label="RM" error={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(activeQuote?.serviceLabourStatus)} type="number" value={roundTo(activeQuote?.serviceLabour,2)} fluid onChange={(event, data) => {
                                                let quote = structuredClone(activeQuote);
                                                quote.serviceLabour = roundTo(data.value,2);
                                                setChangesMade(true);
                                                setActiveQuote(quote);
                                                setQuoteToUpdate(quote);
                                            }} />}/>
                                            <TableHeaderCell content={readOnlyJobStatus.includes(job?.jobStatus?.key) ? activeQuote?.serviceLabourStatus : <Dropdown value={activeQuote?.serviceLabourStatus} options={statusOptions} icon={"caret down"} onChange={(event,data) => {
                                                let quote = structuredClone(activeQuote);
                                                quote.serviceLabourStatus = data.value;
                                                setChangesMade(true);
                                                setActiveQuote(quote);
                                                setQuoteToUpdate(quote);
                                            }}/>}/>
                                        </TableRow>
                                        <TableRow>
                                            <TableHeaderCell content={<><Header textAlign="right" as={"h5"}>Diagnostic</Header></>}/>
                                            <TableHeaderCell colSpan={2} content={<Input disabled={readOnlyJobStatus.includes(job?.jobStatus?.key)} label="RM" error={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(activeQuote?.diagnosticStatus)} type="number" value={roundTo(activeQuote?.diagnostic,2)} fluid onChange={(event, data) => {
                                                let quote = structuredClone(activeQuote);
                                                quote.diagnostic = roundTo(data.value,2);
                                                setChangesMade(true);
                                                setActiveQuote(quote);
                                                setQuoteToUpdate(quote);
                                            }} />}/>
                                            <TableHeaderCell content={readOnlyJobStatus.includes(job?.jobStatus?.key) ? activeQuote?.diagnosticStatus : <Dropdown value={activeQuote?.diagnosticStatus} options={statusOptions} icon={"caret down"} onChange={(event,data) => {
                                                let quote = structuredClone(activeQuote);
                                                quote.diagnosticStatus = data.value;
                                                setChangesMade(true);
                                                setActiveQuote(quote);
                                                setQuoteToUpdate(quote);
                                            }}/>}/>
                                        </TableRow>
                                        <TableRow>
                                            <TableHeaderCell content={<><Header textAlign="right" as={"h5"}>Discount</Header></>}/>
                                            <TableHeaderCell colSpan={3} content={<Input disabled={readOnlyJobStatus.includes(job?.jobStatus?.key)} label="RM" type="number" value={roundTo(activeQuote?.discount,2)} fluid onChange={(event, data) => {
                                                let quote = structuredClone(activeQuote);
                                                quote.discount = roundTo(data.value,2);
                                                setChangesMade(true);
                                                setActiveQuote(quote);
                                                setQuoteToUpdate(quote);
                                            }} />}/>
                                        </TableRow>
                                        <TableRow>
                                            <TableHeaderCell content={<><Header textAlign="right" as={"h5"}>TOTAL PRICE</Header></>}/>
                                            <TableHeaderCell colSpan={3} content={"RM " + parseFloat(activeQuote?.total)?.toFixed(2)}/>
                                        </TableRow>
                                        <TableRow>
                                            <TableHeaderCell content={<><Header textAlign="right" as={"h5"}>Total Cost</Header></>}/>
                                            <TableHeaderCell colSpan={3} content={"RM " + totalCost()?.toFixed(2)}/>
                                        </TableRow>
                                    </TableFooter>
                                    <Autosave interval={3000} data={quoteToUpdate} onSave={updateQuote} />
                                </Table>
                            </RoleAllowed>
                            <RoleAllowed rolesAllowed={[Roles.ROLE_TECHNICIAN]}>
                                <Table compact color="red" celled textAlign="center" unstackable structured>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell content={<>No</>}/>
                                            <TableHeaderCell content={<>SKU</>}/>
                                            <TableHeaderCell content={<>Name</>}/>
                                            <TableHeaderCell content={<>Qty</>}/>
                                            <TableHeaderCell content={<>Status</>}/>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            activeQuote?.lineItem?.map((n,index) => 
                                            <TableRow negative={["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(n?.status)} positive={["ACCEPTED"].includes(n?.status)} key={index}>
                                                <TableCell content={<>{index + 1}</>}/>
                                                <TableCell content={n.sku}/>
                                                <TableCell content={n.name}/>
                                                <TableCell content={n.quantity}/>
                                                <TableCell content={n.status}/>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </RoleAllowed>
                        </VisibleCondition>
                        <VisibleCondition condition={isMobile || isTablet}>
                            <RoleAllowed rolesAllowed={[Roles.ROLE_MANAGER, Roles.ROLE_ADVISOR, Roles.ROLE_TECHNICIAN]}>
                                <Table compact color="red" celled textAlign="center">
                                    <TableBody>
                                        {
                                            activeQuote?.lineItem?.map((n,index) => 
                                            <TableRow style={{borderBottom:"3px solid"}} key={n.id}>
                                                <TableCell>
                                                    <Grid verticalAlign="middle" centered>
                                                        <GridRow columns={2}>
                                                            <GridColumn textAlign="left">
                                                                <Header as={"h3"} content={index + 1} />
                                                            </GridColumn>
                                                            <GridColumn textAlign="right">
                                                                <Label color={["ACCEPTED"].includes(n?.status) ? "green" : ["REJECTED","RETURN_PART","WAITING_FOR_PART"].includes(n?.status) ? "red" : null} content={n?.status?.replace("_", " ")} />
                                                            </GridColumn>
                                                        </GridRow>
                                                        <GridRow columns={2}>
                                                            <GridColumn textAlign="left" width={13}>
                                                                <div>{"NAME : " + n?.name}</div>
                                                                <div>{"SKU : " + n?.sku}</div>
                                                            </GridColumn>
                                                            <GridColumn textAlign="right" width={3}>
                                                                <Label content={"x" + n?.quantity} />
                                                            </GridColumn>
                                                        </GridRow>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </RoleAllowed>
                        </VisibleCondition>
                    </GridRow>
                </VisibleCondition>
                <VisibleCondition condition={!activeQuote && !loadingQuote}>
                    <GridRow columns={1}>
                        <GridColumn>
                                <Button content={"New Quote"} icon="plus" onClick={() => createQuote()}/>
                        </GridColumn>
                    </GridRow>
                </VisibleCondition>
            </Grid>
        </div>
    )
}