import "./Job.css";
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { Button, Container, Grid, GridColumn, GridRow, Input, List, ListContent, ListHeader, ListItem, Message, Segment } from "semantic-ui-react";
import { JOB_ENDPOINT } from "../../constants/API";
import Text from '../../constants/Text';
import Navigation from "../common/Navigation";
import Roles from '../../constants/Roles';
import PATH from '../../constants/Path';
import CreateJobModal from "./new/NewJobModal";

export default function Job () {

    const authentication = useSelector(state => state.authentication);
    const {t} = useTranslation();
    const messageTitle = t(Text.UI_JOB_MESSAGE)
    
    const [loading,setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [page] = useState(0);
    const [size] = useState(10);
    const [jobs, setJobs] = useState([]);
    const [message, setMessage] = useState(messageTitle);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = "Job";
    });

    const searchJob = async () => {
        setLoading(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': authentication.authorization
            }
        }

        //Build URL
        const searchURL = new URL(JOB_ENDPOINT+"/search");
        searchURL.searchParams.append("searchText",searchText)
        searchURL.searchParams.append("size",size)
        searchURL.searchParams.append("page",page)

        const response = await fetch(searchURL,options)
        const body = await response.json();
        if(response.ok)
        {
            setError(false)
            setJobs(body)
            if(jobs.length === 0){
                setMessage("No jobs found");
            }
        }
        else
        {
            setErrorMessage(body.message)
            setError(true)
        }
        setLoading(false)
    }
    
    const jobsList = (jobs) => {

        if(jobs.length > 0)
        {
            return jobs.map((job, idx) => 
                <ListItem key={idx}>
                    <ListContent>
                        <ListHeader>
                            <a href={generatePath(PATH.JOB_DETAILS_PAGE,{id:job.id})}>{job.jobId} - {job.serviceData?.customerVehicleData.registrationNumber} - {job.jobStatus.value}</a>
                        </ListHeader>
                        <label>{t(Text.GENERIC_DATA_JOB_STARTDATETIME)} - {job.jobStartDateTime}</label>
                        <br/>
                        <label>{t(Text.GENERIC_DATA_CUSTOMER_CUSTOMERNAME)} - {job.serviceData?.customerData.firstName}</label>
                        <br/>
                        <label>{t(Text.UI_JOB_DETAILS_CARMODEL)}- {job.serviceData?.customerVehicleData?.vehicleData.posId}</label>
                    </ListContent>
                </ListItem>
            )
        }
        else {
            return (
                <ListItem>
                    <ListContent>
                        <ListHeader>
                            <h4>{message}</h4>
                            {error &&<Message className='support-line-break' error  header={t(Text.GENERIC_MESSAGE_HEADER_ERROR)} content={errorMessage} />}
                        </ListHeader>
                    </ListContent>
                </ListItem>
            )
        }
    }
    
    return(
        <Navigation rolesAllowed={[Roles.ROLE_MANAGER,Roles.ROLE_ADVISOR]} headerText={t(Text.UI_JOB_HEADER)}>
            <Container>
                <Segment className='primary-segment' basic>
                    <Grid>
                        <GridRow>
                            <GridColumn textAlign="left" width={14}>
                                <Input icon={<Button icon='search' onClick={searchJob} />} onChange={(e,searchText) => {setSearchText(searchText.value)}}/>
                            </GridColumn>
                            <GridColumn width={2}>
                                <CreateJobModal/>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <Segment textAlign="left" loading={loading}>
                                    <List divided relaxed>
                                        {jobsList(jobs)}
                                    </List>
                                </Segment>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </Container>
        </Navigation>
    )
}